// System
import { Button, Stack, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';

// Redux
import { useDispatch } from 'react-redux';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';
import { useCreateAviatorRole } from '@Modal/hooks/aviatorRoles/useCreateAviatorRole';

export const AviatorRoleTitle: React.FC = () => {
  const { setShowCreateAviatorRoleForm } = useCreateAviatorRole();
  const { userPermissions } = useAuthorization();
  const permissionToManageRole = userPermissions?.includes('CAN_MANAGE_BACKOFFICE_ROLES');
  const dispatch = useDispatch();

  return (
    <AtLocator type="container" name="BranchTitle">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '4px 0' }}
      >
        <AtLocator type="readonly-text" name="Title">
          <Typography variant="h3">Roles</Typography>
        </AtLocator>
        {permissionToManageRole && (
          <Button
            variant="contained"
            onClick={() => dispatch(setShowCreateAviatorRoleForm(true))}
            size="large"
            sx={{ paddingY: '2px', height: '45px' }}
          >
            Create Role
          </Button>
        )}
      </Stack>
    </AtLocator>
  );
};
