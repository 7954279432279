import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps } from '@mui/material';
// Styles
import Styles from './Logo.module.scss';
// Logo
import AviatorLogo from '../../../shared/ui/layouts/dashboard/nav/AviatorLogo.svg';
// Redux
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';
// ----------------------------------------------------------------------
export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}
const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false }, ref) => {
  const { permissionedRoutes } = useAuthorization();
  const logo = (
    <Box ref={ref} component="div" className={Styles.LogoContainer}>
      <img className={Styles.Logo} src={`${AviatorLogo}`} />
    </Box>
  );
  if (disabledLink) {
    return logo;
  }
  return (
    <Link
      component={RouterLink}
      to={`${permissionedRoutes?.length && permissionedRoutes[0]?.path}`}
      sx={{ display: 'contents' }}
    >
      {logo}
    </Link>
  );
});
export default Logo;
