import { noop } from '@utils/noop';
import * as Yup from 'yup';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string, number } from 'yup';

export type CreateProviderFormState = {
  name: string;
  apiUrl: string;
  maxWin: number | null;
  minBet: number | null;
  maxBet: number | null;
  betSuggestions: string | string[];
  minAutoCashout: number | null;
  maxAutoCashout: number | null;
  defaultAutoCashout: number | null;
  language: string;
  currency: string;
  publicKey: string;
  privateKey: string;
};

export const createProviderFormStateDefault: CreateProviderFormState = {
  name: '',
  apiUrl: '',
  maxWin: null,
  minBet: null,
  maxBet: null,
  betSuggestions: '',
  minAutoCashout: null,
  maxAutoCashout: null,
  defaultAutoCashout: null,
  language: '',
  currency: '',
  publicKey: '',
  privateKey: '',
};
// @ts-ignore
export const createProviderSchemaDefault: ObjectSchema<Partial<CreateProviderFormState>> = object<
  Partial<CreateProviderFormState>
>().shape({
  name: string().required('name is required field'),
  apiUrl: string().required('api url is required field'),
  maxWin: number().required('maximum win is required field'),
  minBet: number().required('minimum bey is required field'),
  maxBet: number().required('maximum bet is required field'),
  betSuggestions: Yup.mixed()
    .required('Bet suggestions are required')
    .test(
      'is-valid-type',
      'Bet suggestions must be a string, an array of strings, a number, or an array of numbers',
      (value) =>
        typeof value === 'string' ||
        typeof value === 'number' ||
        (Array.isArray(value) &&
          value.every((item) => typeof item === 'string' || typeof item === 'number'))
    ),
  minAutoCashout: number().required('minimum auto cashout is required field'),
  maxAutoCashout: number().required('maximum auto cashout is required field'),
  defaultAutoCashout: number().required('default auto cashout is required field'),
  language: string(),
  currency: string(),
  publicKey: string().required('public key is required field'),
  privateKey: string().required('private key is required field'),
});

type CreateProviderFormContextType = {
  schema: ObjectSchema<Partial<CreateProviderFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateProviderFormState>>>;
  methods?: UseFormReturn<CreateProviderFormState>;
};

const createProviderFormContextDefault = {
  schema: createProviderSchemaDefault,
  setSchema: noop,
} satisfies CreateProviderFormContextType;

export const CreateProviderFormContext = createContext<CreateProviderFormContextType>(
  createProviderFormContextDefault
);

export const useCreateProviderForm = () => {
  const { methods } = useContext(CreateProviderFormContext);
  if (!methods) {
    throw new Error('CreateProviderFormContext is empty');
  }

  return methods;
};

export const useManageCreateProviderSchema = () => {
  const { setSchema } = useContext(CreateProviderFormContext);

  return { setSchema };
};
