import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

// Hooks
import useResponsive from '@Hooks/shared/useResponsive';

// Components
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';

export default function DashboardLayout() {
  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const location = useLocation();
  const isMapPage = location.pathname === '/map';
  const { pathname } = useLocation();

  const pagesToHideHeader = ['/operator'];

  const hideHeader = pagesToHideHeader.includes(pathname);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  return (
    <>
      {!hideHeader && <Header onOpenNav={handleOpen} />}

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
          maxHeight: '100vh',
        }}
      >
        {!hideHeader && isDesktop ? (
          <NavMini />
        ) : (
          <NavVertical openNav={open} onCloseNav={handleClose} />
        )}

        <Main
          sx={{
            paddingX: isMapPage ? '0px' : '16px',
            paddingTop: isMapPage ? '64px' : '70px',
            overflowY: 'scroll',
          }}
        >
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
