import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type AggregatedAviatorAnalytics = {
  __typename?: 'AggregatedAviatorAnalytics';
  records: Array<AggregatedRoundTransaction>;
  totalRoundByDate: Scalars['JSON']['output'];
};

export type AggregatedRoundTransaction = {
  __typename?: 'AggregatedRoundTransaction';
  averageBetAmount?: Maybe<Scalars['Float']['output']>;
  averageWinAmount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  largestBetAmount?: Maybe<Scalars['Float']['output']>;
  largestWin?: Maybe<Scalars['Float']['output']>;
  lowestBetAmount?: Maybe<Scalars['Float']['output']>;
  lowestWin?: Maybe<Scalars['Float']['output']>;
  numberOfRounds?: Maybe<Scalars['Float']['output']>;
  numberOfUsers?: Maybe<Scalars['Int']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  totalBetAmount?: Maybe<Scalars['Float']['output']>;
  totalLosingBets?: Maybe<Scalars['Int']['output']>;
  totalWinningBets?: Maybe<Scalars['Int']['output']>;
  totalWonAmount?: Maybe<Scalars['Float']['output']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export enum BackOfficePermissionsEnum {
  CanManageAviatorRounds = 'CAN_MANAGE_AVIATOR_ROUNDS',
  CanManageAviatorTransactions = 'CAN_MANAGE_AVIATOR_TRANSACTIONS',
  CanManageBackofficePermissions = 'CAN_MANAGE_BACKOFFICE_PERMISSIONS',
  CanManageBackofficeRoles = 'CAN_MANAGE_BACKOFFICE_ROLES',
  CanManageBackofficeUsers = 'CAN_MANAGE_BACKOFFICE_USERS',
  CanManageFreebets = 'CAN_MANAGE_FREEBETS',
  CanManageFreebetsCampaigns = 'CAN_MANAGE_FREEBETS_CAMPAIGNS',
  CanManageLocalizations = 'CAN_MANAGE_LOCALIZATIONS',
  CanManageProviders = 'CAN_MANAGE_PROVIDERS',
  CanManageRoundConfigs = 'CAN_MANAGE_ROUND_CONFIGS',
  CanViewAviatorAnalytics = 'CAN_VIEW_AVIATOR_ANALYTICS',
  CanViewAviatorRounds = 'CAN_VIEW_AVIATOR_ROUNDS',
  CanViewAviatorRoundTransactions = 'CAN_VIEW_AVIATOR_ROUND_TRANSACTIONS',
  CanViewBackofficePermissions = 'CAN_VIEW_BACKOFFICE_PERMISSIONS',
  CanViewBackofficeRoles = 'CAN_VIEW_BACKOFFICE_ROLES',
  CanViewBackofficeTasks = 'CAN_VIEW_BACKOFFICE_TASKS',
  CanViewBackofficeUsers = 'CAN_VIEW_BACKOFFICE_USERS',
  CanViewFreebets = 'CAN_VIEW_FREEBETS',
  CanViewFreebetsCampaigns = 'CAN_VIEW_FREEBETS_CAMPAIGNS',
  CanViewLocalizations = 'CAN_VIEW_LOCALIZATIONS',
  CanViewProviders = 'CAN_VIEW_PROVIDERS',
  CanViewProviderTransactions = 'CAN_VIEW_PROVIDER_TRANSACTIONS',
  CanViewRoundConfigs = 'CAN_VIEW_ROUND_CONFIGS',
}

export type BackOfficeSession = {
  __typename?: 'BackOfficeSession';
  provider?: Maybe<Provider>;
  session: Session;
};

export type FailedSendCreditTask = {
  __typename?: 'FailedSendCreditTask';
  attempts: Scalars['Int']['output'];
  data?: Maybe<FailedSendCreditTaskData>;
  failedReason?: Maybe<Scalars['String']['output']>;
  finishedOn?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  processedOn?: Maybe<Scalars['Float']['output']>;
};

export type FailedSendCreditTaskData = {
  __typename?: 'FailedSendCreditTaskData';
  amount: Scalars['Float']['output'];
  betId: Scalars['String']['output'];
  category: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  language: Scalars['String']['output'];
  operation: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  providerApiUrl: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  roundId: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  win: Scalars['Float']['output'];
};

export enum FreeBetAccessStatusEnum {
  Active = 'ACTIVE',
  Revoked = 'REVOKED',
}

export type FreeBetsCampaign = {
  __typename?: 'FreeBetsCampaign';
  _id: Scalars['String']['output'];
  campaignUniqueId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  providerId: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FreeBetsUsage = {
  __typename?: 'FreeBetsUsage';
  _id: Scalars['String']['output'];
  betNominal: Scalars['Float']['output'];
  campaignId: Scalars['String']['output'];
  count: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  minMultiplier: Scalars['Float']['output'];
  playerId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  totalWin: Scalars['Float']['output'];
  usage: Scalars['Float']['output'];
  validityDate: Scalars['DateTime']['output'];
};

export type FreeBetsUsageLog = {
  __typename?: 'FreeBetsUsageLog';
  _id: Scalars['String']['output'];
  campaignId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  gameId: Scalars['Float']['output'];
  playerId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  roundId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usageId: Scalars['String']['output'];
  win: Scalars['Float']['output'];
};

export type InputAssignRoleIds = {
  roleIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type InputCreateConfig = {
  bettingTime: Scalars['Int']['input'];
  margin: Scalars['Int']['input'];
  maxCrashPoint: Scalars['Int']['input'];
  waitingTime: Scalars['Int']['input'];
};

export type InputCreateLocalization = {
  componentCopyCopied?: InputMaybe<Scalars['String']['input']>;
  componentErrorConnectFailedDescription?: InputMaybe<Scalars['String']['input']>;
  componentErrorConnectFailedHome?: InputMaybe<Scalars['String']['input']>;
  componentErrorConnectFailedTitle?: InputMaybe<Scalars['String']['input']>;
  componentErrorDisconnectDescription?: InputMaybe<Scalars['String']['input']>;
  componentErrorDisconnectTitle?: InputMaybe<Scalars['String']['input']>;
  componentErrorGlobalAuthenticationDescription?: InputMaybe<Scalars['String']['input']>;
  componentErrorGlobalAuthenticationTitle?: InputMaybe<Scalars['String']['input']>;
  componentErrorGlobalDefaultDescription?: InputMaybe<Scalars['String']['input']>;
  componentErrorGlobalDefaultTitle?: InputMaybe<Scalars['String']['input']>;
  componentErrorGlobalGeneralDescription?: InputMaybe<Scalars['String']['input']>;
  componentErrorGlobalGeneralTitle?: InputMaybe<Scalars['String']['input']>;
  componentErrorGlobalHome?: InputMaybe<Scalars['String']['input']>;
  componentErrorGlobalSwitchedDescription?: InputMaybe<Scalars['String']['input']>;
  componentErrorGlobalSwitchedTitle?: InputMaybe<Scalars['String']['input']>;
  componentInputApply?: InputMaybe<Scalars['String']['input']>;
  gameAvatarSave?: InputMaybe<Scalars['String']['input']>;
  gameAvatarTitle?: InputMaybe<Scalars['String']['input']>;
  gameBetAutoBet?: InputMaybe<Scalars['String']['input']>;
  gameBetAutoCashOut?: InputMaybe<Scalars['String']['input']>;
  gameBetBet?: InputMaybe<Scalars['String']['input']>;
  gameBetCancel?: InputMaybe<Scalars['String']['input']>;
  gameBetCashOut?: InputMaybe<Scalars['String']['input']>;
  gameBetErrorBalance?: InputMaybe<Scalars['String']['input']>;
  gameBetErrorFreeBet?: InputMaybe<Scalars['String']['input']>;
  gameBetErrorGlobal?: InputMaybe<Scalars['String']['input']>;
  gameBetFreeBet?: InputMaybe<Scalars['String']['input']>;
  gameBetFreeBetCheck?: InputMaybe<Scalars['String']['input']>;
  gameBetWaiting?: InputMaybe<Scalars['String']['input']>;
  gameDisplayFlew?: InputMaybe<Scalars['String']['input']>;
  gameDisplayLoading?: InputMaybe<Scalars['String']['input']>;
  gameFooterProvablyFair?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsHistoryDate?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsHistoryGranted?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsHistoryMinCashout?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsTabActive?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsTabHistory?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsTableBet?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsTableCashout?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsTableCount?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsTableEmpty?: InputMaybe<Scalars['String']['input']>;
  gameFreeBetsTitle?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsClientDescription?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsClientLabel?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsCombinedDecimal?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsCombinedDescription?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsCombinedHex?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsCombinedLabel?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsCombinedResult?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsDate?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsPlayerSeed?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsPlayerTitle?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsProvablyFair?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsServerDescription?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsServerLabel?: InputMaybe<Scalars['String']['input']>;
  gameHistoryDetailsTitle?: InputMaybe<Scalars['String']['input']>;
  gameHistoryTitle?: InputMaybe<Scalars['String']['input']>;
  gameId: Scalars['Int']['input'];
  gameLimitsMax?: InputMaybe<Scalars['String']['input']>;
  gameLimitsMin?: InputMaybe<Scalars['String']['input']>;
  gameLimitsTitle?: InputMaybe<Scalars['String']['input']>;
  gameLimitsWin?: InputMaybe<Scalars['String']['input']>;
  gameMenuAnimation?: InputMaybe<Scalars['String']['input']>;
  gameMenuFreeBets?: InputMaybe<Scalars['String']['input']>;
  gameMenuHome?: InputMaybe<Scalars['String']['input']>;
  gameMenuLimits?: InputMaybe<Scalars['String']['input']>;
  gameMenuMusic?: InputMaybe<Scalars['String']['input']>;
  gameMenuMyBets?: InputMaybe<Scalars['String']['input']>;
  gameMenuRules?: InputMaybe<Scalars['String']['input']>;
  gameMenuSound?: InputMaybe<Scalars['String']['input']>;
  gameMyBetsTableEmpty?: InputMaybe<Scalars['String']['input']>;
  gameMyBetsTitle?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsMyBet?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsMyDate?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsMyEmpty?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsMyWin?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsRoundCurrentPrevious?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsRoundCurrentTotal?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsRoundPreviousPrevious?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsRoundPreviousTotal?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsRoundTableBet?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsRoundTableEmpty?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsRoundTablePlayer?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsRoundTableWin?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsTabCurrent?: InputMaybe<Scalars['String']['input']>;
  gamePlacedBetsTabMy?: InputMaybe<Scalars['String']['input']>;
  gameProvablyFairHowLongDescription?: InputMaybe<Scalars['String']['input']>;
  gameProvablyFairHowLongTitle?: InputMaybe<Scalars['String']['input']>;
  gameProvablyFairHowShortDescription?: InputMaybe<Scalars['String']['input']>;
  gameProvablyFairHowShortTitle?: InputMaybe<Scalars['String']['input']>;
  gameProvablyFairHowTitle?: InputMaybe<Scalars['String']['input']>;
  gameProvablyFairReliabilityDescription?: InputMaybe<Array<Scalars['String']['input']>>;
  gameProvablyFairReliabilityTitle?: InputMaybe<Scalars['String']['input']>;
  gameProvablyFairTitle?: InputMaybe<Scalars['String']['input']>;
  gameProvablyFairWhatDescription?: InputMaybe<Scalars['String']['input']>;
  gameProvablyFairWhatTitle?: InputMaybe<Scalars['String']['input']>;
  gameRulesAccordion0Description?: InputMaybe<Array<Scalars['String']['input']>>;
  gameRulesAccordion0Title?: InputMaybe<Scalars['String']['input']>;
  gameRulesAccordion1Description?: InputMaybe<Array<Scalars['String']['input']>>;
  gameRulesAccordion1Title?: InputMaybe<Scalars['String']['input']>;
  gameRulesAccordion2Description?: InputMaybe<Array<Scalars['String']['input']>>;
  gameRulesAccordion2Title?: InputMaybe<Scalars['String']['input']>;
  gameRulesAccordion3Description?: InputMaybe<Array<Scalars['String']['input']>>;
  gameRulesAccordion3Title?: InputMaybe<Scalars['String']['input']>;
  gameRulesAccordion4Description?: InputMaybe<Array<Scalars['String']['input']>>;
  gameRulesAccordion4Title?: InputMaybe<Scalars['String']['input']>;
  gameRulesAdditionalTitle?: InputMaybe<Scalars['String']['input']>;
  gameRulesHowDescription?: InputMaybe<Scalars['String']['input']>;
  gameRulesHowTitle?: InputMaybe<Scalars['String']['input']>;
  gameRulesSlider1?: InputMaybe<Scalars['String']['input']>;
  gameRulesSlider2?: InputMaybe<Scalars['String']['input']>;
  gameRulesTitle?: InputMaybe<Scalars['String']['input']>;
  gameRulesVideoDescription?: InputMaybe<Scalars['String']['input']>;
  gameRulesVideoTitle?: InputMaybe<Scalars['String']['input']>;
  gameWinSnackbarLabel?: InputMaybe<Scalars['String']['input']>;
  gameWinSnackbarWin?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
};

export type InputCreateRole = {
  description: Scalars['String']['input'];
  isEditable: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  permissions: Array<BackOfficePermissionsEnum>;
};

export type InputCreateUser = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  providerId?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InputDeleteRole = {
  roleId: Scalars['String']['input'];
};

export type InputDeleteUserRole = {
  roleId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type InputFilterFreeBetUsageLogs = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  createdEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  createdStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxWin?: InputMaybe<Scalars['Float']['input']>;
  minWin?: InputMaybe<Scalars['Float']['input']>;
  playerId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  roundId?: InputMaybe<Scalars['String']['input']>;
  usageId?: InputMaybe<Scalars['String']['input']>;
};

export type InputFilterFreeBetsCampagins = {
  campaignUniqueId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<FreeBetAccessStatusEnum>;
};

export type InputFilterFreeBetsUsage = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  createdAtEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  freebetUsageLogId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxBetNominal?: InputMaybe<Scalars['Float']['input']>;
  maxCount?: InputMaybe<Scalars['Float']['input']>;
  maxMultiplier?: InputMaybe<Scalars['Float']['input']>;
  maxTotalWin?: InputMaybe<Scalars['Float']['input']>;
  maxUsage?: InputMaybe<Scalars['Float']['input']>;
  minBetNominal?: InputMaybe<Scalars['Float']['input']>;
  minCount?: InputMaybe<Scalars['Float']['input']>;
  minMultiplier?: InputMaybe<Scalars['Float']['input']>;
  minTotalWin?: InputMaybe<Scalars['Float']['input']>;
  minUsage?: InputMaybe<Scalars['Float']['input']>;
  playerId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  validityEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  validityStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InputFilterProviderTransaction = {
  category?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  createdStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  gameId?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  maxAmount?: InputMaybe<Scalars['Float']['input']>;
  maxBalance?: InputMaybe<Scalars['Float']['input']>;
  maxMultiplier?: InputMaybe<Scalars['Float']['input']>;
  minAmount?: InputMaybe<Scalars['Float']['input']>;
  minBalance?: InputMaybe<Scalars['Float']['input']>;
  minMultiplier?: InputMaybe<Scalars['Float']['input']>;
  operation?: InputMaybe<Scalars['String']['input']>;
  playerId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  roundId?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type InputFilterRoundTransaction = {
  clientHash?: InputMaybe<Scalars['String']['input']>;
  createdEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  createdStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  freebetUsageLogId?: InputMaybe<Scalars['String']['input']>;
  freebetUsageRecordId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAutoCashout?: InputMaybe<Scalars['Boolean']['input']>;
  isFreebet?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  maxBetAmount?: InputMaybe<Scalars['Float']['input']>;
  maxBetNominal?: InputMaybe<Scalars['Float']['input']>;
  maxCashoutDate?: InputMaybe<Scalars['DateTime']['input']>;
  maxMaxMultiplier?: InputMaybe<Scalars['Float']['input']>;
  maxMultiplier?: InputMaybe<Scalars['Float']['input']>;
  maxWin?: InputMaybe<Scalars['Float']['input']>;
  minBetAmount?: InputMaybe<Scalars['Float']['input']>;
  minBetNominal?: InputMaybe<Scalars['Float']['input']>;
  minCashoutDate?: InputMaybe<Scalars['DateTime']['input']>;
  minMaxMultiplier?: InputMaybe<Scalars['Float']['input']>;
  minMultiplier?: InputMaybe<Scalars['Float']['input']>;
  minWin?: InputMaybe<Scalars['Float']['input']>;
  playerId?: InputMaybe<Scalars['String']['input']>;
  playerRoundBetId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  roundHash?: InputMaybe<Scalars['String']['input']>;
  roundId?: InputMaybe<Scalars['String']['input']>;
  serverHash?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type InputFilterRounds = {
  clientHash?: InputMaybe<Scalars['String']['input']>;
  createdEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  createdStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxMultiplierCrash?: InputMaybe<Scalars['Float']['input']>;
  minMultiplierCrash?: InputMaybe<Scalars['Float']['input']>;
  phase?: InputMaybe<Scalars['String']['input']>;
  previousRoundId?: InputMaybe<Scalars['String']['input']>;
  roundHash?: InputMaybe<Scalars['String']['input']>;
  serverHash?: InputMaybe<Scalars['String']['input']>;
};

export type InputRemoveRolePermission = {
  permission: BackOfficePermissionsEnum;
  roleId: Scalars['String']['input'];
};

export type InputUpdateLocalization = {
  localizationId: Scalars['String']['input'];
  localizationKey: Scalars['String']['input'];
  localizationValue: Array<Scalars['String']['input']>;
};

export type InputUpdateRoundStatus = {
  configId: Scalars['String']['input'];
  status: RoundStatus;
};

export type Localization = {
  __typename?: 'Localization';
  _id: Scalars['String']['output'];
  gameId: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  translations: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addLocalizationKey: Scalars['Boolean']['output'];
  addPermission: Role;
  assignRoleIds: Scalars['Boolean']['output'];
  createAviatorRoundConfigs: RoundConfig;
  createLocalization: Localization;
  createProvider: Provider;
  createRole: Role;
  createUser: User;
  deleteRole: Scalars['Int']['output'];
  deleteUserRole: Scalars['Int']['output'];
  refreshToken: AuthPayload;
  removeRolePermission: Scalars['Int']['output'];
  signIn: AuthPayload;
  updateLocalization: Scalars['Boolean']['output'];
  updateProvider: Scalars['Int']['output'];
  updateRoundStatus: Scalars['Boolean']['output'];
};

export type MutationAddLocalizationKeyArgs = {
  input: InputUpdateLocalization;
};

export type MutationAddPermissionArgs = {
  permission: Scalars['String']['input'];
};

export type MutationAssignRoleIdsArgs = {
  input: InputAssignRoleIds;
};

export type MutationCreateAviatorRoundConfigsArgs = {
  input: InputCreateConfig;
};

export type MutationCreateLocalizationArgs = {
  input: InputCreateLocalization;
};

export type MutationCreateProviderArgs = {
  input: ProviderInput;
};

export type MutationCreateRoleArgs = {
  input: InputCreateRole;
};

export type MutationCreateUserArgs = {
  input: InputCreateUser;
};

export type MutationDeleteRoleArgs = {
  input: InputDeleteRole;
};

export type MutationDeleteUserRoleArgs = {
  input: InputDeleteUserRole;
};

export type MutationRefreshTokenArgs = {
  token: Scalars['String']['input'];
};

export type MutationRemoveRolePermissionArgs = {
  input: InputRemoveRolePermission;
};

export type MutationSignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationUpdateLocalizationArgs = {
  input: InputUpdateLocalization;
};

export type MutationUpdateProviderArgs = {
  input: ProviderInput;
  providerId: Scalars['String']['input'];
};

export type MutationUpdateRoundStatusArgs = {
  input: InputUpdateRoundStatus;
};

export type PaginatedFreeBets = {
  __typename?: 'PaginatedFreeBets';
  docs: Array<FreeBetsUsage>;
  limit: Scalars['Float']['output'];
  page: Scalars['Float']['output'];
  skip?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type PaginatedFreeBetsCampaigns = {
  __typename?: 'PaginatedFreeBetsCampaigns';
  docs: Array<FreeBetsCampaign>;
  limit: Scalars['Float']['output'];
  page: Scalars['Float']['output'];
  skip?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type PaginatedFreeBetsUsageLogs = {
  __typename?: 'PaginatedFreeBetsUsageLogs';
  docs: Array<FreeBetsUsageLog>;
  limit: Scalars['Float']['output'];
  page: Scalars['Float']['output'];
  skip?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type PaginatedProviderTransactions = {
  __typename?: 'PaginatedProviderTransactions';
  docs: Array<ProviderTransaction>;
  limit: Scalars['Float']['output'];
  page: Scalars['Float']['output'];
  skip?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type PaginatedRounds = {
  __typename?: 'PaginatedRounds';
  docs: Array<Round>;
  limit: Scalars['Float']['output'];
  page: Scalars['Float']['output'];
  skip?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type PaginatedTransactions = {
  __typename?: 'PaginatedTransactions';
  docs: Array<RoundTransaction>;
  limit: Scalars['Float']['output'];
  page: Scalars['Float']['output'];
  skip?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type Provider = {
  __typename?: 'Provider';
  apiUrl: Scalars['String']['output'];
  betSuggestions?: Maybe<Array<Scalars['Float']['output']>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['String']['output'];
  defaultAutoCashout?: Maybe<Scalars['Float']['output']>;
  homePageUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  language: Scalars['String']['output'];
  maxAutoCashout?: Maybe<Scalars['Float']['output']>;
  maxBet?: Maybe<Scalars['Float']['output']>;
  maxWin?: Maybe<Scalars['Float']['output']>;
  minAutoCashout?: Maybe<Scalars['Float']['output']>;
  minBet?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  passPhrase: Scalars['String']['output'];
  privateKey: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProviderInput = {
  apiUrl: Scalars['String']['input'];
  betSuggestions: Array<Scalars['Float']['input']>;
  currency: Scalars['String']['input'];
  defaultAutoCashout: Scalars['Float']['input'];
  homePageUrl?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  maxAutoCashout: Scalars['Float']['input'];
  maxBet: Scalars['Float']['input'];
  maxWin: Scalars['Float']['input'];
  minAutoCashout: Scalars['Float']['input'];
  minBet: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type ProviderTransaction = {
  __typename?: 'ProviderTransaction';
  _id: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  balance: Scalars['Float']['output'];
  category: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  gameId: Scalars['Int']['output'];
  language?: Maybe<Scalars['String']['output']>;
  multiplier?: Maybe<Scalars['Float']['output']>;
  operation: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  roundId: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  aggregatedAviatorAnalytics: AggregatedAviatorAnalytics;
  aggregatedRoundTransactionsByProviderId: AggregatedRoundTransaction;
  aggregatedRoundTransactionsByRoundIdProviderId: Array<AggregatedRoundTransaction>;
  aviatorFailedSendCreditTasks: Array<FailedSendCreditTask>;
  aviatorRoundConfigs: Array<RoundConfig>;
  aviatorRoundPlayers: RoundPlayers;
  countRoundsAviator: Scalars['Int']['output'];
  freeBetCampaign?: Maybe<FreeBetsCampaign>;
  freeBetUsageLog?: Maybe<FreeBetsUsageLog>;
  freeBetsUsage?: Maybe<FreeBetsUsage>;
  localization?: Maybe<Localization>;
  localizations: Array<Localization>;
  paginatedAviatorRoundTransactions: PaginatedTransactions;
  paginatedAviatorRounds: PaginatedRounds;
  paginatedFreeBets: PaginatedFreeBets;
  paginatedFreeBetsCampaigns: PaginatedFreeBetsCampaigns;
  paginatedFreeBetsUsageLogs: PaginatedFreeBetsUsageLogs;
  paginatedProviderTransactions: PaginatedProviderTransactions;
  permissions: Array<Scalars['String']['output']>;
  provider: Provider;
  providerTransaction?: Maybe<Provider>;
  providers: Array<Provider>;
  refreshLocalizationsCache: Scalars['Boolean']['output'];
  refreshProvidersCache: Scalars['Boolean']['output'];
  refreshProvidersSecrets: Scalars['Boolean']['output'];
  role: Role;
  roles: Array<Role>;
  roundTransactions: Array<RoundTransaction>;
  session: BackOfficeSession;
  user: User;
  users: Array<User>;
};

export type QueryAggregatedAviatorAnalyticsArgs = {
  end: Scalars['DateTime']['input'];
  providerId?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['DateTime']['input'];
};

export type QueryAggregatedRoundTransactionsByProviderIdArgs = {
  providerId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAggregatedRoundTransactionsByRoundIdProviderIdArgs = {
  providerId?: InputMaybe<Scalars['String']['input']>;
  roundId: Scalars['String']['input'];
};

export type QueryAviatorRoundPlayersArgs = {
  roundId: Scalars['String']['input'];
};

export type QueryCountRoundsAviatorArgs = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type QueryFreeBetCampaignArgs = {
  id: Scalars['String']['input'];
};

export type QueryFreeBetUsageLogArgs = {
  id: Scalars['String']['input'];
};

export type QueryFreeBetsUsageArgs = {
  id: Scalars['String']['input'];
};

export type QueryLocalizationArgs = {
  gameId: Scalars['Float']['input'];
  language: Scalars['String']['input'];
};

export type QueryPaginatedAviatorRoundTransactionsArgs = {
  filter?: InputMaybe<InputFilterRoundTransaction>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryPaginatedAviatorRoundsArgs = {
  filter?: InputMaybe<InputFilterRounds>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryPaginatedFreeBetsArgs = {
  filter?: InputMaybe<InputFilterFreeBetsUsage>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPaginatedFreeBetsCampaignsArgs = {
  filter?: InputMaybe<InputFilterFreeBetsCampagins>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPaginatedFreeBetsUsageLogsArgs = {
  filter?: InputMaybe<InputFilterFreeBetUsageLogs>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryPaginatedProviderTransactionsArgs = {
  filter?: InputMaybe<InputFilterProviderTransaction>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryProviderArgs = {
  providerId: Scalars['String']['input'];
};

export type QueryProviderTransactionArgs = {
  id: Scalars['String']['input'];
};

export type QueryRoleArgs = {
  id: Scalars['String']['input'];
};

export type QueryRoundTransactionsArgs = {
  roundId: Scalars['String']['input'];
};

export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

export type Role = {
  __typename?: 'Role';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isEditable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
};

export type Round = {
  __typename?: 'Round';
  _id?: Maybe<Scalars['String']['output']>;
  clientHash?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['String']['output']>;
  multiplierCrash?: Maybe<Scalars['Float']['output']>;
  playerIds?: Maybe<Array<Scalars['String']['output']>>;
  roundHash?: Maybe<Scalars['String']['output']>;
  serverHash?: Maybe<Scalars['String']['output']>;
};

export type RoundConfig = {
  __typename?: 'RoundConfig';
  _id?: Maybe<Scalars['String']['output']>;
  bettingTime: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  margin: Scalars['Int']['output'];
  maxCrashPoint: Scalars['Int']['output'];
  waitingTime: Scalars['Int']['output'];
};

export type RoundPlayer = {
  __typename?: 'RoundPlayer';
  playerId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type RoundPlayers = {
  __typename?: 'RoundPlayers';
  players: Array<RoundPlayer>;
  round: Round;
};

export enum RoundStatus {
  Paused = 'PAUSED',
  Running = 'RUNNING',
}

export type RoundTransaction = {
  __typename?: 'RoundTransaction';
  _id?: Maybe<Scalars['String']['output']>;
  betAmount: Scalars['Float']['output'];
  cashoutDate?: Maybe<Scalars['DateTime']['output']>;
  clientHash?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  freebetUsageLogId?: Maybe<Scalars['String']['output']>;
  freebetUsageRecordId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isAutoCashout: Scalars['Boolean']['output'];
  language?: Maybe<Scalars['String']['output']>;
  maxMultiplier?: Maybe<Scalars['Float']['output']>;
  multiplier?: Maybe<Scalars['Float']['output']>;
  playerId: Scalars['String']['output'];
  playerRoundBetId: Scalars['String']['output'];
  roundHash?: Maybe<Scalars['String']['output']>;
  roundId: Scalars['String']['output'];
  serverHash?: Maybe<Scalars['String']['output']>;
  win?: Maybe<Scalars['Float']['output']>;
};

export type Session = {
  __typename?: 'Session';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isSuper?: Maybe<Scalars['Boolean']['output']>;
  permissions: Array<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  password: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  roleIds: Array<Scalars['String']['output']>;
};

export type AggregatedAviatorAnalyticsQueryVariables = Exact<{
  start: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
  providerId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AggregatedAviatorAnalyticsQuery = {
  __typename?: 'Query';
  aggregatedAviatorAnalytics: {
    __typename?: 'AggregatedAviatorAnalytics';
    totalRoundByDate: any;
    records: Array<{
      __typename?: 'AggregatedRoundTransaction';
      totalBetAmount?: number | null;
      totalWonAmount?: number | null;
      date?: string | null;
      providerId?: string | null;
      numberOfUsers?: number | null;
      totalLosingBets?: number | null;
      totalWinningBets?: number | null;
      largestBetAmount?: number | null;
      largestWin?: number | null;
      lowestBetAmount?: number | null;
      lowestWin?: number | null;
      averageBetAmount?: number | null;
      averageWinAmount?: number | null;
      numberOfRounds?: number | null;
    }>;
  };
};

export type PaginatedFreeBetsCampaignsQueryVariables = Exact<{
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  search: Scalars['String']['input'];
  filter: InputFilterFreeBetsCampagins;
}>;

export type PaginatedFreeBetsCampaignsQuery = {
  __typename?: 'Query';
  paginatedFreeBetsCampaigns: {
    __typename?: 'PaginatedFreeBetsCampaigns';
    total: number;
    page: number;
    limit: number;
    skip?: number | null;
    docs: Array<{
      __typename?: 'FreeBetsCampaign';
      _id: string;
      description: string;
      providerId: string;
      startDate: any;
      endDate: any;
      campaignUniqueId: string;
      status: string;
      createdAt: any;
    }>;
  };
};

export type PaginatedFreeBetsQueryVariables = Exact<{
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  filter: InputFilterFreeBetsUsage;
  search: Scalars['String']['input'];
}>;

export type PaginatedFreeBetsQuery = {
  __typename?: 'Query';
  paginatedFreeBets: {
    __typename?: 'PaginatedFreeBets';
    total: number;
    page: number;
    limit: number;
    skip?: number | null;
    docs: Array<{
      __typename?: 'FreeBetsUsage';
      _id: string;
      campaignId: string;
      requestId: string;
      providerId: string;
      playerId: string;
      usage: number;
      totalWin: number;
      validityDate: any;
      description: string;
      count: number;
      minMultiplier: number;
      betNominal: number;
      status: string;
      createdAt: any;
    }>;
  };
};

export type PaginatedFreeBetsUsageLogsQueryVariables = Exact<{
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  filter: InputFilterFreeBetUsageLogs;
}>;

export type PaginatedFreeBetsUsageLogsQuery = {
  __typename?: 'Query';
  paginatedFreeBetsUsageLogs: {
    __typename?: 'PaginatedFreeBetsUsageLogs';
    total: number;
    page: number;
    limit: number;
    skip?: number | null;
    docs: Array<{
      __typename?: 'FreeBetsUsageLog';
      _id: string;
      campaignId: string;
      usageId: string;
      providerId: string;
      roundId: string;
      gameId: number;
      win: number;
      createdAt: any;
      updatedAt: any;
    }>;
  };
};

export type GetProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type GetProvidersQuery = {
  __typename?: 'Query';
  providers: Array<{
    __typename?: 'Provider';
    id: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    name: string;
    apiUrl: string;
    publicKey: string;
    privateKey: string;
    passPhrase: string;
    maxWin?: number | null;
    minBet?: number | null;
    maxBet?: number | null;
    betSuggestions?: Array<number> | null;
    language: string;
    minAutoCashout?: number | null;
    defaultAutoCashout?: number | null;
    maxAutoCashout?: number | null;
    currency: string;
  }>;
};

export type CreateProviderMutationVariables = Exact<{
  input: ProviderInput;
}>;

export type CreateProviderMutation = {
  __typename?: 'Mutation';
  createProvider: { __typename?: 'Provider'; id: string; name: string };
};

export type GetProviderQueryVariables = Exact<{
  providerId: Scalars['String']['input'];
}>;

export type GetProviderQuery = {
  __typename?: 'Query';
  provider: {
    __typename?: 'Provider';
    id: string;
    name: string;
    apiUrl: string;
    maxWin?: number | null;
    minBet?: number | null;
    maxBet?: number | null;
    betSuggestions?: Array<number> | null;
    minAutoCashout?: number | null;
    maxAutoCashout?: number | null;
    defaultAutoCashout?: number | null;
    language: string;
    currency: string;
    publicKey: string;
    privateKey: string;
  };
};

export type UpdateProviderMutationVariables = Exact<{
  providerId: Scalars['String']['input'];
  input: ProviderInput;
}>;

export type UpdateProviderMutation = { __typename?: 'Mutation'; updateProvider: number };

export type CreateAviatorRoundConfigsMutationVariables = Exact<{
  input: InputCreateConfig;
}>;

export type CreateAviatorRoundConfigsMutation = {
  __typename?: 'Mutation';
  createAviatorRoundConfigs: { __typename?: 'RoundConfig'; id: string };
};

export type GetRoundTransactionsQueryVariables = Exact<{
  roundId: Scalars['String']['input'];
}>;

export type GetRoundTransactionsQuery = {
  __typename?: 'Query';
  roundTransactions: Array<{
    __typename?: 'RoundTransaction';
    id: string;
    playerId: string;
    roundId: string;
    betAmount: number;
    isAutoCashout: boolean;
    maxMultiplier?: number | null;
    playerRoundBetId: string;
  }>;
};

export type PaginatedAviatorRoundsQueryVariables = Exact<{
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  filter: InputFilterRounds;
}>;

export type PaginatedAviatorRoundsQuery = {
  __typename?: 'Query';
  paginatedAviatorRounds: {
    __typename?: 'PaginatedRounds';
    total: number;
    page: number;
    limit: number;
    skip?: number | null;
    docs: Array<{
      __typename?: 'Round';
      _id?: string | null;
      multiplierCrash?: number | null;
      playerIds?: Array<string> | null;
      createdAt: any;
      roundHash?: string | null;
      clientHash?: string | null;
      serverHash?: string | null;
    }>;
  };
};

export type PaginatedAviatorRoundTransactionsQueryVariables = Exact<{
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  filter: InputFilterRoundTransaction;
}>;

export type PaginatedAviatorRoundTransactionsQuery = {
  __typename?: 'Query';
  paginatedAviatorRoundTransactions: {
    __typename?: 'PaginatedTransactions';
    total: number;
    page: number;
    limit: number;
    skip?: number | null;
    docs: Array<{
      __typename?: 'RoundTransaction';
      _id?: string | null;
      freebetUsageLogId?: string | null;
      freebetUsageRecordId?: string | null;
      playerId: string;
      roundId: string;
      win?: number | null;
      betAmount: number;
      multiplier?: number | null;
      cashoutDate?: any | null;
      isAutoCashout: boolean;
      maxMultiplier?: number | null;
      roundHash?: string | null;
      serverHash?: string | null;
      clientHash?: string | null;
      playerRoundBetId: string;
      createdAt: any;
      language?: string | null;
      currency?: string | null;
    }>;
  };
};

export type PaginatedProviderTransactionsQueryVariables = Exact<{
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
  filter: InputFilterProviderTransaction;
}>;

export type PaginatedProviderTransactionsQuery = {
  __typename?: 'Query';
  paginatedProviderTransactions: {
    __typename?: 'PaginatedProviderTransactions';
    total: number;
    page: number;
    limit: number;
    skip?: number | null;
    docs: Array<{
      __typename?: 'ProviderTransaction';
      _id: string;
      createdAt?: any | null;
      category: string;
      language?: string | null;
      currency?: string | null;
      roundId: string;
      transactionId?: string | null;
      operation: string;
      reason?: string | null;
      providerId: string;
      playerId: string;
      amount: number;
      multiplier?: number | null;
      balance: number;
      gameId: number;
    }>;
  };
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    password: string;
    providerId: string;
    roleIds: Array<string>;
  }>;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = {
  __typename?: 'Query';
  roles: Array<{
    __typename?: 'Role';
    id: string;
    name: string;
    description: string;
    permissions: Array<string>;
    isEditable: boolean;
  }>;
};

export type GetRoleQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetRoleQuery = {
  __typename?: 'Query';
  role: {
    __typename?: 'Role';
    id: string;
    name: string;
    description: string;
    permissions: Array<string>;
  };
};

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPermissionsQuery = { __typename?: 'Query'; permissions: Array<string> };

export type CreateRoleMutationVariables = Exact<{
  input: InputCreateRole;
}>;

export type CreateRoleMutation = {
  __typename?: 'Mutation';
  createRole: { __typename?: 'Role'; id: string };
};

export type CreateUserMutationVariables = Exact<{
  input: InputCreateUser;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'User'; id: string };
};

export type DeleteRoleMutationVariables = Exact<{
  input: InputDeleteRole;
}>;

export type DeleteRoleMutation = { __typename?: 'Mutation'; deleteRole: number };

export type GetSessionQueryVariables = Exact<{ [key: string]: never }>;

export type GetSessionQuery = {
  __typename?: 'Query';
  session: {
    __typename?: 'BackOfficeSession';
    provider?: { __typename?: 'Provider'; id: string; name: string } | null;
    session: {
      __typename?: 'Session';
      id: string;
      email?: string | null;
      providerId?: string | null;
      permissions: Array<string>;
      isSuper?: boolean | null;
    };
  };
};

export type SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  signIn: { __typename?: 'AuthPayload'; accessToken: string; refreshToken: string };
};

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken: { __typename?: 'AuthPayload'; accessToken: string; refreshToken: string };
};

export const AggregatedAviatorAnalyticsDocument = gql`
  query aggregatedAviatorAnalytics($start: DateTime!, $end: DateTime!, $providerId: String) {
    aggregatedAviatorAnalytics(start: $start, end: $end, providerId: $providerId) {
      totalRoundByDate
      records {
        totalBetAmount
        totalWonAmount
        date
        providerId
        numberOfUsers
        totalLosingBets
        totalWinningBets
        largestBetAmount
        largestWin
        lowestBetAmount
        lowestWin
        averageBetAmount
        averageWinAmount
        numberOfRounds
      }
    }
  }
`;

/**
 * __useAggregatedAviatorAnalyticsQuery__
 *
 * To run a query within a React component, call `useAggregatedAviatorAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregatedAviatorAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregatedAviatorAnalyticsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useAggregatedAviatorAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AggregatedAviatorAnalyticsQuery,
    AggregatedAviatorAnalyticsQueryVariables
  > &
    ({ variables: AggregatedAviatorAnalyticsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AggregatedAviatorAnalyticsQuery, AggregatedAviatorAnalyticsQueryVariables>(
    AggregatedAviatorAnalyticsDocument,
    options
  );
}
export function useAggregatedAviatorAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AggregatedAviatorAnalyticsQuery,
    AggregatedAviatorAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AggregatedAviatorAnalyticsQuery,
    AggregatedAviatorAnalyticsQueryVariables
  >(AggregatedAviatorAnalyticsDocument, options);
}
export function useAggregatedAviatorAnalyticsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AggregatedAviatorAnalyticsQuery,
    AggregatedAviatorAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AggregatedAviatorAnalyticsQuery,
    AggregatedAviatorAnalyticsQueryVariables
  >(AggregatedAviatorAnalyticsDocument, options);
}
export type AggregatedAviatorAnalyticsQueryHookResult = ReturnType<
  typeof useAggregatedAviatorAnalyticsQuery
>;
export type AggregatedAviatorAnalyticsLazyQueryHookResult = ReturnType<
  typeof useAggregatedAviatorAnalyticsLazyQuery
>;
export type AggregatedAviatorAnalyticsSuspenseQueryHookResult = ReturnType<
  typeof useAggregatedAviatorAnalyticsSuspenseQuery
>;
export type AggregatedAviatorAnalyticsQueryResult = Apollo.QueryResult<
  AggregatedAviatorAnalyticsQuery,
  AggregatedAviatorAnalyticsQueryVariables
>;
export const PaginatedFreeBetsCampaignsDocument = gql`
  query paginatedFreeBetsCampaigns(
    $limit: Float!
    $page: Float!
    $search: String!
    $filter: InputFilterFreeBetsCampagins!
  ) {
    paginatedFreeBetsCampaigns(limit: $limit, page: $page, search: $search, filter: $filter) {
      total
      page
      limit
      skip
      docs {
        _id
        description
        providerId
        startDate
        endDate
        campaignUniqueId
        status
        createdAt
      }
    }
  }
`;

/**
 * __usePaginatedFreeBetsCampaignsQuery__
 *
 * To run a query within a React component, call `usePaginatedFreeBetsCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedFreeBetsCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedFreeBetsCampaignsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedFreeBetsCampaignsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedFreeBetsCampaignsQuery,
    PaginatedFreeBetsCampaignsQueryVariables
  > &
    ({ variables: PaginatedFreeBetsCampaignsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedFreeBetsCampaignsQuery, PaginatedFreeBetsCampaignsQueryVariables>(
    PaginatedFreeBetsCampaignsDocument,
    options
  );
}
export function usePaginatedFreeBetsCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedFreeBetsCampaignsQuery,
    PaginatedFreeBetsCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedFreeBetsCampaignsQuery,
    PaginatedFreeBetsCampaignsQueryVariables
  >(PaginatedFreeBetsCampaignsDocument, options);
}
export function usePaginatedFreeBetsCampaignsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaginatedFreeBetsCampaignsQuery,
    PaginatedFreeBetsCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaginatedFreeBetsCampaignsQuery,
    PaginatedFreeBetsCampaignsQueryVariables
  >(PaginatedFreeBetsCampaignsDocument, options);
}
export type PaginatedFreeBetsCampaignsQueryHookResult = ReturnType<
  typeof usePaginatedFreeBetsCampaignsQuery
>;
export type PaginatedFreeBetsCampaignsLazyQueryHookResult = ReturnType<
  typeof usePaginatedFreeBetsCampaignsLazyQuery
>;
export type PaginatedFreeBetsCampaignsSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedFreeBetsCampaignsSuspenseQuery
>;
export type PaginatedFreeBetsCampaignsQueryResult = Apollo.QueryResult<
  PaginatedFreeBetsCampaignsQuery,
  PaginatedFreeBetsCampaignsQueryVariables
>;
export const PaginatedFreeBetsDocument = gql`
  query paginatedFreeBets(
    $limit: Float!
    $page: Float!
    $filter: InputFilterFreeBetsUsage!
    $search: String!
  ) {
    paginatedFreeBets(limit: $limit, page: $page, filter: $filter, search: $search) {
      total
      page
      limit
      skip
      docs {
        _id
        campaignId
        requestId
        providerId
        playerId
        usage
        totalWin
        validityDate
        description
        count
        minMultiplier
        betNominal
        status
        createdAt
      }
    }
  }
`;

/**
 * __usePaginatedFreeBetsQuery__
 *
 * To run a query within a React component, call `usePaginatedFreeBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedFreeBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedFreeBetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePaginatedFreeBetsQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedFreeBetsQuery, PaginatedFreeBetsQueryVariables> &
    ({ variables: PaginatedFreeBetsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedFreeBetsQuery, PaginatedFreeBetsQueryVariables>(
    PaginatedFreeBetsDocument,
    options
  );
}
export function usePaginatedFreeBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedFreeBetsQuery, PaginatedFreeBetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedFreeBetsQuery, PaginatedFreeBetsQueryVariables>(
    PaginatedFreeBetsDocument,
    options
  );
}
export function usePaginatedFreeBetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaginatedFreeBetsQuery,
    PaginatedFreeBetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaginatedFreeBetsQuery, PaginatedFreeBetsQueryVariables>(
    PaginatedFreeBetsDocument,
    options
  );
}
export type PaginatedFreeBetsQueryHookResult = ReturnType<typeof usePaginatedFreeBetsQuery>;
export type PaginatedFreeBetsLazyQueryHookResult = ReturnType<typeof usePaginatedFreeBetsLazyQuery>;
export type PaginatedFreeBetsSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedFreeBetsSuspenseQuery
>;
export type PaginatedFreeBetsQueryResult = Apollo.QueryResult<
  PaginatedFreeBetsQuery,
  PaginatedFreeBetsQueryVariables
>;
export const PaginatedFreeBetsUsageLogsDocument = gql`
  query paginatedFreeBetsUsageLogs(
    $limit: Float!
    $page: Float!
    $filter: InputFilterFreeBetUsageLogs!
  ) {
    paginatedFreeBetsUsageLogs(limit: $limit, page: $page, filter: $filter) {
      total
      page
      limit
      skip
      docs {
        _id
        campaignId
        usageId
        providerId
        roundId
        gameId
        win
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __usePaginatedFreeBetsUsageLogsQuery__
 *
 * To run a query within a React component, call `usePaginatedFreeBetsUsageLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedFreeBetsUsageLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedFreeBetsUsageLogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedFreeBetsUsageLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedFreeBetsUsageLogsQuery,
    PaginatedFreeBetsUsageLogsQueryVariables
  > &
    ({ variables: PaginatedFreeBetsUsageLogsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedFreeBetsUsageLogsQuery, PaginatedFreeBetsUsageLogsQueryVariables>(
    PaginatedFreeBetsUsageLogsDocument,
    options
  );
}
export function usePaginatedFreeBetsUsageLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedFreeBetsUsageLogsQuery,
    PaginatedFreeBetsUsageLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedFreeBetsUsageLogsQuery,
    PaginatedFreeBetsUsageLogsQueryVariables
  >(PaginatedFreeBetsUsageLogsDocument, options);
}
export function usePaginatedFreeBetsUsageLogsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaginatedFreeBetsUsageLogsQuery,
    PaginatedFreeBetsUsageLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaginatedFreeBetsUsageLogsQuery,
    PaginatedFreeBetsUsageLogsQueryVariables
  >(PaginatedFreeBetsUsageLogsDocument, options);
}
export type PaginatedFreeBetsUsageLogsQueryHookResult = ReturnType<
  typeof usePaginatedFreeBetsUsageLogsQuery
>;
export type PaginatedFreeBetsUsageLogsLazyQueryHookResult = ReturnType<
  typeof usePaginatedFreeBetsUsageLogsLazyQuery
>;
export type PaginatedFreeBetsUsageLogsSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedFreeBetsUsageLogsSuspenseQuery
>;
export type PaginatedFreeBetsUsageLogsQueryResult = Apollo.QueryResult<
  PaginatedFreeBetsUsageLogsQuery,
  PaginatedFreeBetsUsageLogsQueryVariables
>;
export const GetProvidersDocument = gql`
  query getProviders {
    providers {
      id
      createdAt
      updatedAt
      name
      apiUrl
      publicKey
      privateKey
      passPhrase
      maxWin
      minBet
      maxBet
      betSuggestions
      language
      minAutoCashout
      defaultAutoCashout
      maxAutoCashout
      currency
    }
  }
`;

/**
 * __useGetProvidersQuery__
 *
 * To run a query within a React component, call `useGetProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProvidersQuery, GetProvidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProvidersQuery, GetProvidersQueryVariables>(
    GetProvidersDocument,
    options
  );
}
export function useGetProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProvidersQuery, GetProvidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProvidersQuery, GetProvidersQueryVariables>(
    GetProvidersDocument,
    options
  );
}
export function useGetProvidersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetProvidersQuery, GetProvidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProvidersQuery, GetProvidersQueryVariables>(
    GetProvidersDocument,
    options
  );
}
export type GetProvidersQueryHookResult = ReturnType<typeof useGetProvidersQuery>;
export type GetProvidersLazyQueryHookResult = ReturnType<typeof useGetProvidersLazyQuery>;
export type GetProvidersSuspenseQueryHookResult = ReturnType<typeof useGetProvidersSuspenseQuery>;
export type GetProvidersQueryResult = Apollo.QueryResult<
  GetProvidersQuery,
  GetProvidersQueryVariables
>;
export const CreateProviderDocument = gql`
  mutation createProvider($input: ProviderInput!) {
    createProvider(input: $input) {
      id
      name
    }
  }
`;
export type CreateProviderMutationFn = Apollo.MutationFunction<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;

/**
 * __useCreateProviderMutation__
 *
 * To run a mutation, you first call `useCreateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMutation, { data, loading, error }] = useCreateProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProviderMutation, CreateProviderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProviderMutation, CreateProviderMutationVariables>(
    CreateProviderDocument,
    options
  );
}
export type CreateProviderMutationHookResult = ReturnType<typeof useCreateProviderMutation>;
export type CreateProviderMutationResult = Apollo.MutationResult<CreateProviderMutation>;
export type CreateProviderMutationOptions = Apollo.BaseMutationOptions<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;
export const GetProviderDocument = gql`
  query getProvider($providerId: String!) {
    provider(providerId: $providerId) {
      id
      name
      apiUrl
      maxWin
      minBet
      maxBet
      betSuggestions
      minAutoCashout
      maxAutoCashout
      defaultAutoCashout
      language
      currency
      publicKey
      privateKey
    }
  }
`;

/**
 * __useGetProviderQuery__
 *
 * To run a query within a React component, call `useGetProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetProviderQuery(
  baseOptions: Apollo.QueryHookOptions<GetProviderQuery, GetProviderQueryVariables> &
    ({ variables: GetProviderQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProviderQuery, GetProviderQueryVariables>(GetProviderDocument, options);
}
export function useGetProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProviderQuery, GetProviderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProviderQuery, GetProviderQueryVariables>(
    GetProviderDocument,
    options
  );
}
export function useGetProviderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetProviderQuery, GetProviderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProviderQuery, GetProviderQueryVariables>(
    GetProviderDocument,
    options
  );
}
export type GetProviderQueryHookResult = ReturnType<typeof useGetProviderQuery>;
export type GetProviderLazyQueryHookResult = ReturnType<typeof useGetProviderLazyQuery>;
export type GetProviderSuspenseQueryHookResult = ReturnType<typeof useGetProviderSuspenseQuery>;
export type GetProviderQueryResult = Apollo.QueryResult<
  GetProviderQuery,
  GetProviderQueryVariables
>;
export const UpdateProviderDocument = gql`
  mutation updateProvider($providerId: String!, $input: ProviderInput!) {
    updateProvider(providerId: $providerId, input: $input)
  }
`;
export type UpdateProviderMutationFn = Apollo.MutationFunction<
  UpdateProviderMutation,
  UpdateProviderMutationVariables
>;

/**
 * __useUpdateProviderMutation__
 *
 * To run a mutation, you first call `useUpdateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderMutation, { data, loading, error }] = useUpdateProviderMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProviderMutation, UpdateProviderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProviderMutation, UpdateProviderMutationVariables>(
    UpdateProviderDocument,
    options
  );
}
export type UpdateProviderMutationHookResult = ReturnType<typeof useUpdateProviderMutation>;
export type UpdateProviderMutationResult = Apollo.MutationResult<UpdateProviderMutation>;
export type UpdateProviderMutationOptions = Apollo.BaseMutationOptions<
  UpdateProviderMutation,
  UpdateProviderMutationVariables
>;
export const CreateAviatorRoundConfigsDocument = gql`
  mutation createAviatorRoundConfigs($input: InputCreateConfig!) {
    createAviatorRoundConfigs(input: $input) {
      id
    }
  }
`;
export type CreateAviatorRoundConfigsMutationFn = Apollo.MutationFunction<
  CreateAviatorRoundConfigsMutation,
  CreateAviatorRoundConfigsMutationVariables
>;

/**
 * __useCreateAviatorRoundConfigsMutation__
 *
 * To run a mutation, you first call `useCreateAviatorRoundConfigsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAviatorRoundConfigsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAviatorRoundConfigsMutation, { data, loading, error }] = useCreateAviatorRoundConfigsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAviatorRoundConfigsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAviatorRoundConfigsMutation,
    CreateAviatorRoundConfigsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAviatorRoundConfigsMutation,
    CreateAviatorRoundConfigsMutationVariables
  >(CreateAviatorRoundConfigsDocument, options);
}
export type CreateAviatorRoundConfigsMutationHookResult = ReturnType<
  typeof useCreateAviatorRoundConfigsMutation
>;
export type CreateAviatorRoundConfigsMutationResult =
  Apollo.MutationResult<CreateAviatorRoundConfigsMutation>;
export type CreateAviatorRoundConfigsMutationOptions = Apollo.BaseMutationOptions<
  CreateAviatorRoundConfigsMutation,
  CreateAviatorRoundConfigsMutationVariables
>;
export const GetRoundTransactionsDocument = gql`
  query getRoundTransactions($roundId: String!) {
    roundTransactions(roundId: $roundId) {
      id
      playerId
      roundId
      betAmount
      isAutoCashout
      maxMultiplier
      playerRoundBetId
    }
  }
`;

/**
 * __useGetRoundTransactionsQuery__
 *
 * To run a query within a React component, call `useGetRoundTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundTransactionsQuery({
 *   variables: {
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useGetRoundTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoundTransactionsQuery,
    GetRoundTransactionsQueryVariables
  > &
    ({ variables: GetRoundTransactionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoundTransactionsQuery, GetRoundTransactionsQueryVariables>(
    GetRoundTransactionsDocument,
    options
  );
}
export function useGetRoundTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoundTransactionsQuery,
    GetRoundTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoundTransactionsQuery, GetRoundTransactionsQueryVariables>(
    GetRoundTransactionsDocument,
    options
  );
}
export function useGetRoundTransactionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRoundTransactionsQuery,
    GetRoundTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRoundTransactionsQuery, GetRoundTransactionsQueryVariables>(
    GetRoundTransactionsDocument,
    options
  );
}
export type GetRoundTransactionsQueryHookResult = ReturnType<typeof useGetRoundTransactionsQuery>;
export type GetRoundTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetRoundTransactionsLazyQuery
>;
export type GetRoundTransactionsSuspenseQueryHookResult = ReturnType<
  typeof useGetRoundTransactionsSuspenseQuery
>;
export type GetRoundTransactionsQueryResult = Apollo.QueryResult<
  GetRoundTransactionsQuery,
  GetRoundTransactionsQueryVariables
>;
export const PaginatedAviatorRoundsDocument = gql`
  query paginatedAviatorRounds($limit: Float!, $page: Float!, $filter: InputFilterRounds!) {
    paginatedAviatorRounds(limit: $limit, page: $page, filter: $filter) {
      total
      page
      limit
      skip
      docs {
        _id
        multiplierCrash
        playerIds
        createdAt
        roundHash
        clientHash
        serverHash
      }
    }
  }
`;

/**
 * __usePaginatedAviatorRoundsQuery__
 *
 * To run a query within a React component, call `usePaginatedAviatorRoundsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedAviatorRoundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedAviatorRoundsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedAviatorRoundsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedAviatorRoundsQuery,
    PaginatedAviatorRoundsQueryVariables
  > &
    ({ variables: PaginatedAviatorRoundsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedAviatorRoundsQuery, PaginatedAviatorRoundsQueryVariables>(
    PaginatedAviatorRoundsDocument,
    options
  );
}
export function usePaginatedAviatorRoundsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedAviatorRoundsQuery,
    PaginatedAviatorRoundsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedAviatorRoundsQuery, PaginatedAviatorRoundsQueryVariables>(
    PaginatedAviatorRoundsDocument,
    options
  );
}
export function usePaginatedAviatorRoundsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaginatedAviatorRoundsQuery,
    PaginatedAviatorRoundsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaginatedAviatorRoundsQuery, PaginatedAviatorRoundsQueryVariables>(
    PaginatedAviatorRoundsDocument,
    options
  );
}
export type PaginatedAviatorRoundsQueryHookResult = ReturnType<
  typeof usePaginatedAviatorRoundsQuery
>;
export type PaginatedAviatorRoundsLazyQueryHookResult = ReturnType<
  typeof usePaginatedAviatorRoundsLazyQuery
>;
export type PaginatedAviatorRoundsSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedAviatorRoundsSuspenseQuery
>;
export type PaginatedAviatorRoundsQueryResult = Apollo.QueryResult<
  PaginatedAviatorRoundsQuery,
  PaginatedAviatorRoundsQueryVariables
>;
export const PaginatedAviatorRoundTransactionsDocument = gql`
  query paginatedAviatorRoundTransactions(
    $limit: Float!
    $page: Float!
    $filter: InputFilterRoundTransaction!
  ) {
    paginatedAviatorRoundTransactions(limit: $limit, page: $page, filter: $filter) {
      total
      page
      limit
      skip
      docs {
        _id
        freebetUsageLogId
        freebetUsageRecordId
        playerId
        roundId
        win
        betAmount
        multiplier
        cashoutDate
        isAutoCashout
        maxMultiplier
        roundHash
        serverHash
        clientHash
        playerRoundBetId
        createdAt
        language
        currency
      }
    }
  }
`;

/**
 * __usePaginatedAviatorRoundTransactionsQuery__
 *
 * To run a query within a React component, call `usePaginatedAviatorRoundTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedAviatorRoundTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedAviatorRoundTransactionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedAviatorRoundTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedAviatorRoundTransactionsQuery,
    PaginatedAviatorRoundTransactionsQueryVariables
  > &
    (
      | { variables: PaginatedAviatorRoundTransactionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedAviatorRoundTransactionsQuery,
    PaginatedAviatorRoundTransactionsQueryVariables
  >(PaginatedAviatorRoundTransactionsDocument, options);
}
export function usePaginatedAviatorRoundTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedAviatorRoundTransactionsQuery,
    PaginatedAviatorRoundTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedAviatorRoundTransactionsQuery,
    PaginatedAviatorRoundTransactionsQueryVariables
  >(PaginatedAviatorRoundTransactionsDocument, options);
}
export function usePaginatedAviatorRoundTransactionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaginatedAviatorRoundTransactionsQuery,
    PaginatedAviatorRoundTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaginatedAviatorRoundTransactionsQuery,
    PaginatedAviatorRoundTransactionsQueryVariables
  >(PaginatedAviatorRoundTransactionsDocument, options);
}
export type PaginatedAviatorRoundTransactionsQueryHookResult = ReturnType<
  typeof usePaginatedAviatorRoundTransactionsQuery
>;
export type PaginatedAviatorRoundTransactionsLazyQueryHookResult = ReturnType<
  typeof usePaginatedAviatorRoundTransactionsLazyQuery
>;
export type PaginatedAviatorRoundTransactionsSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedAviatorRoundTransactionsSuspenseQuery
>;
export type PaginatedAviatorRoundTransactionsQueryResult = Apollo.QueryResult<
  PaginatedAviatorRoundTransactionsQuery,
  PaginatedAviatorRoundTransactionsQueryVariables
>;
export const PaginatedProviderTransactionsDocument = gql`
  query paginatedProviderTransactions(
    $limit: Float!
    $page: Float!
    $filter: InputFilterProviderTransaction!
  ) {
    paginatedProviderTransactions(limit: $limit, page: $page, filter: $filter) {
      total
      page
      limit
      skip
      docs {
        _id
        createdAt
        category
        language
        currency
        roundId
        transactionId
        operation
        reason
        providerId
        playerId
        amount
        multiplier
        balance
        gameId
      }
    }
  }
`;

/**
 * __usePaginatedProviderTransactionsQuery__
 *
 * To run a query within a React component, call `usePaginatedProviderTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedProviderTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedProviderTransactionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedProviderTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedProviderTransactionsQuery,
    PaginatedProviderTransactionsQueryVariables
  > &
    ({ variables: PaginatedProviderTransactionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedProviderTransactionsQuery,
    PaginatedProviderTransactionsQueryVariables
  >(PaginatedProviderTransactionsDocument, options);
}
export function usePaginatedProviderTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedProviderTransactionsQuery,
    PaginatedProviderTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedProviderTransactionsQuery,
    PaginatedProviderTransactionsQueryVariables
  >(PaginatedProviderTransactionsDocument, options);
}
export function usePaginatedProviderTransactionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaginatedProviderTransactionsQuery,
    PaginatedProviderTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PaginatedProviderTransactionsQuery,
    PaginatedProviderTransactionsQueryVariables
  >(PaginatedProviderTransactionsDocument, options);
}
export type PaginatedProviderTransactionsQueryHookResult = ReturnType<
  typeof usePaginatedProviderTransactionsQuery
>;
export type PaginatedProviderTransactionsLazyQueryHookResult = ReturnType<
  typeof usePaginatedProviderTransactionsLazyQuery
>;
export type PaginatedProviderTransactionsSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedProviderTransactionsSuspenseQuery
>;
export type PaginatedProviderTransactionsQueryResult = Apollo.QueryResult<
  PaginatedProviderTransactionsQuery,
  PaginatedProviderTransactionsQueryVariables
>;
export const GetUsersDocument = gql`
  query getUsers {
    users {
      id
      email
      password
      providerId
      roleIds
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetRolesDocument = gql`
  query getRoles {
    roles {
      id
      name
      description
      permissions
      isEditable
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetRoleDocument = gql`
  query getRole($id: String!) {
    role(id: $id) {
      id
      name
      description
      permissions
    }
  }
`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(
  baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables> &
    ({ variables: GetRoleQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
}
export function useGetRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
}
export function useGetRoleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
}
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleSuspenseQueryHookResult = ReturnType<typeof useGetRoleSuspenseQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetPermissionsDocument = gql`
  query getPermissions {
    permissions
  }
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export function useGetPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetPermissionsSuspenseQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const CreateRoleDocument = gql`
  mutation createRole($input: InputCreateRole!) {
    createRole(input: $input) {
      id
    }
  }
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(
    CreateRoleDocument,
    options
  );
}
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($input: InputCreateUser!) {
    createUser(input: $input) {
      id
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteRoleDocument = gql`
  mutation deleteRole($input: InputDeleteRole!) {
    deleteRole(input: $input)
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(
    DeleteRoleDocument,
    options
  );
}
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;
export const GetSessionDocument = gql`
  query getSession {
    session {
      provider {
        id
        name
      }
      session {
        id
        email
        providerId
        permissions
        isSuper
      }
    }
  }
`;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSessionQuery, GetSessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
}
export function useGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSessionQuery, GetSessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export function useGetSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetSessionQuery, GetSessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<typeof useGetSessionLazyQuery>;
export type GetSessionSuspenseQueryHookResult = ReturnType<typeof useGetSessionSuspenseQuery>;
export type GetSessionQueryResult = Apollo.QueryResult<GetSessionQuery, GetSessionQueryVariables>;
export const SignInDocument = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
    options
  );
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
