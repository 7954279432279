import { SetupProviderFormState } from '@Forms/providers/SetupProviderFormContext';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';
import { ProviderItemDto } from '@contracts/api/dto/providers/ProviderDto';

export const useSetupProviderUseCaseMapper = (provider: ProviderItemDto) => {
  return {
    toFormState: async (providerId: string): Promise<SetupProviderFormState | undefined> => {
      return {
        name: provider?.name,
        apiUrl: provider?.apiUrl,
        maxWin: provider?.maxWin,
        minBet: provider?.minBet,
        maxBet: provider?.maxBet,
        // @ts-ignore
        betSuggestions: provider?.betSuggestions,
        minAutoCashout: provider?.minAutoCashout,
        maxAutoCashout: provider?.maxAutoCashout,
        defaultAutoCashout: provider?.defaultAutoCashout,
        language: provider?.language || null,
        currency: provider?.currency || null,
      };
    },
    toDto: (formState: SetupProviderFormState): UpdateRequest<SetupProviderFormState> => {
      // @ts-ignore
      return {
        body: {
          name: formState.name,
          apiUrl: formState.apiUrl,
          maxWin: formState.maxWin,
          minBet: formState.minBet,
          maxBet: formState.maxBet,
          betSuggestions: formState.betSuggestions,
          minAutoCashout: formState.minAutoCashout,
          maxAutoCashout: formState.maxAutoCashout,
          defaultAutoCashout: formState.defaultAutoCashout,
          language: formState.language,
          currency: formState.currency,
        },
      };
    },
  };
};
