// System
import { memo } from 'react';
import { Box, Stack } from '@mui/material';

// Components
import NavList from './NavList';

// Hooks
import { useRoles } from '@Hooks/shared/useRoles';

// Types
import { NavSectionProps, NavListProps } from '../types';
import { RouteConfig } from '@Hooks/shared/useRoles';

// Styles
import Styles from './NavSectionMini.module.scss';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';

function NavSectionMini({ data }: NavSectionProps) {
  const { userPermissions } = useAuthorization();
  const { allRoutes } = useRoles();

  const permissionedRoutes = allRoutes?.filter(
    (route: RouteConfig) => userPermissions && userPermissions.includes(route?.routePermission)
  );

  return (
    <Stack className={Styles.NavSectionMini}>
      {data.map((group, index) => (
        <Items
          key={group.subheader}
          items={permissionedRoutes}
          isLastGroup={index + 1 === data.length}
        />
      ))}
    </Stack>
  );
}

export default memo(NavSectionMini);

type ItemsProps = {
  items: NavListProps[];
  isLastGroup: boolean;
};

function Items({ items, isLastGroup }: ItemsProps) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      ))}

      {!isLastGroup && (
        <Box
          sx={{
            width: 24,
            height: '1px',
            bgcolor: 'divider',
            my: '8px !important',
          }}
        />
      )}
    </>
  );
}
