// System
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

// Redux
import { RouteConfig, useRoles } from '@Hooks/shared/useRoles';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  setUserPermissions,
  setCurrentUser,
  setPermissionedRoutes,
  setAccessToken,
} from '@Modal/reducers/authorization/authorizationSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import {
  useGetSessionLazyQuery,
  useRefreshTokenMutation,
  useSignInMutation,
} from '@Src/generated.graphql';

export const useAuthorization = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [signIn] = useSignInMutation();
  const [getSessionLazyQuery] = useGetSessionLazyQuery();
  const [handleRefreshToken] = useRefreshTokenMutation();

  const { allRoutes } = useRoles();
  const { currentUser, userPermissions, permissionedRoutes } = useSelector(
    (state: StoreInterface) => state?.authorization
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleLogInReq = async (email: string, password: string) => {
    try {
      // Sign In
      const response = await signIn({ variables: { email, password } });
      Cookies.set('accessToken', response?.data?.signIn?.accessToken || '');
      Cookies.set('refreshToken', response?.data?.signIn?.refreshToken || '');
      // Get User Session
      const sessionData = await getSessionLazyQuery();
      dispatch(setCurrentUser(sessionData?.data?.session?.session));
      dispatch(setUserPermissions(sessionData?.data?.session?.session?.permissions));
      const permissionedRoutes = allRoutes
        ?.filter((route: RouteConfig) =>
          sessionData?.data?.session?.session?.permissions?.includes(route?.routePermission)
        )
        .map((route) => ({
          path: route.path,
          title: route.title,
          routePermission: route.routePermission,
        }));

      dispatch(setPermissionedRoutes(permissionedRoutes));
      permissionedRoutes?.length && navigate(permissionedRoutes[0]?.path);
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const fetchSessionData = async () => {
    const refreshToken = Cookies.get('refreshToken');
    try {
      const sessionData = await getSessionLazyQuery();
      dispatch(setCurrentUser(sessionData?.data?.session?.session));
      dispatch(setUserPermissions(sessionData?.data?.session?.session?.permissions));
      const permissionedRoutes = allRoutes
        ?.filter((route: RouteConfig) =>
          sessionData?.data?.session?.session?.permissions?.includes(route?.routePermission)
        )
        .map((route) => ({
          path: route.path,
          title: route.title,
          routePermission: route.routePermission,
        }));
      dispatch(setPermissionedRoutes(permissionedRoutes));
    } catch (error) {
      const response = await handleRefreshToken({
        variables: {
          // @ts-ignore
          token: refreshToken,
        },
      });
      const newAccessToken = response?.data?.refreshToken?.accessToken;
      const newRefreshToken = response?.data?.refreshToken?.refreshToken;
      newAccessToken && Cookies?.set('accessToken', newAccessToken);
      newRefreshToken && Cookies?.set('refreshToken', newRefreshToken);
    }
  };

  useEffect(() => {
    fetchSessionData();
  }, []);

  const handleAccountLogout = () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    navigate('/');
  };

  return {
    // store
    allRoutes,
    currentUser,
    userPermissions,
    permissionedRoutes,

    // actions
    setAccessToken,
    setUserPermissions,

    // handlers
    handleLogInReq,
    handleAccountLogout,
  };
};
