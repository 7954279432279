// System
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

type ProtectedRouteProps = {
  allowedRoutes: string[];
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoutes }) => {
  const location = useLocation();

  const currentPath = location.pathname;
  const accessToken = Cookies.get('accessToken');

  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }
    if (!allowedRoutes.includes(currentPath) && allowedRoutes?.length > 0) {
      navigate('/notFound');
    }
  }, [allowedRoutes]);

  return <Outlet />;
};

export default ProtectedRoute;
