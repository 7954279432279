// React
import { useState } from 'react';

// Components
import { RolesPage } from '@Pages/RolesPage';
import { RoundsRoute } from '@Routes/RoundsRoute';
import { AnalyticsPage } from '@Pages/AnalyticPage';
import { ProvidersRoute } from '@Routes/ProvidersRoute/ProvidersRoute';
import { TransactionPage } from '@Pages/TransactionPage/TransactionPage';
import { ICONS } from '@Components/layouts/dashboard/nav/config-navigation';

export type RouteConfig = {
  path: string;
  component: React.ComponentType;
  title: string;
  icon: JSX.Element;
  routePermission: string;
};

export const useRoles = () => {
  const [setAvailableRoutes] = useState([]);
  const [loading] = useState(true);

  const allRoutes: RouteConfig[] = [
    {
      path: '/providers',
      component: ProvidersRoute,
      title: 'Providers',
      icon: ICONS.user,
      routePermission: 'CAN_VIEW_PROVIDERS',
    },
    {
      path: '/rounds',
      component: RoundsRoute,
      title: 'Rounds',
      icon: ICONS.pricing,
      routePermission: 'CAN_VIEW_AVIATOR_ROUNDS',
    },
    {
      path: '/transactions',
      component: TransactionPage,
      title: 'Transactions',
      icon: ICONS.navTransactions,
      routePermission: 'CAN_VIEW_PROVIDER_TRANSACTIONS' || 'CAN_VIEW_AVIATOR_ROUND_TRANSACTIONS',
    },
    {
      path: '/users',
      component: RolesPage,
      title: 'Users',
      icon: ICONS.roles,
      routePermission:
        'CAN_VIEW_BACKOFFICE_USERS' || 'CAN_VIEW_BACKOFFICE_ROLES' || 'CAN_VIEW_FREEBETS',
    },
    {
      path: '/campaigns',
      component: RolesPage,
      title: 'Campaigns',
      icon: ICONS.rentals,
      routePermission: 'CAN_VIEW_FREEBETS' || 'CAN_VIEW_FREEBETS_CAMPAIGNS',
    },
    {
      path: '/analytics',
      component: AnalyticsPage,
      title: 'Analytics',
      icon: ICONS.analytics,
      routePermission: 'CAN_VIEW_AVIATOR_ANALYTICS',
    },
  ];

  return {
    loading,
    allRoutes,
    setAvailableRoutes,
  };
};
