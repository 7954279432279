// System
import Cookies from 'js-cookie';
import ReactDOM from 'react-dom/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';

// Components
import { App } from './app/App';
import { RootContainer } from '@Components/root/RootContainer';

const httpLink = createHttpLink({
  uri: 'https://api.dev.crush.playcom.ge:82/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('accessToken');

  if (!token) {
    return <div>Loading...</div>;
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const jsx = (
  <ApolloProvider client={client}>
    <RootContainer>
      <App />
    </RootContainer>
  </ApolloProvider>
);

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(jsx);
}
