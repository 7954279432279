// System
import React, { useEffect, useMemo } from 'react';

// Components
import { TableBodyEx, TableContainerEx, TableEx } from '@Components/shared/tables/components';
import {
  ColumnVisibilityPresenter,
  TableHeadColumnsPresenter,
  TableHeadPresenter,
  TableRowPresenter,
} from '@Components/shared/tables/presenters';
import { LoadingScreen } from '@Components/shared/loaders/LoadingScreen';

// Utils
import { applyColumnVisibility } from '@utils/data/applyColumnVisibility';

//  Table
import { useUserTableColumns } from './useUserTableColumns';

// Redux
import { UserItemDto } from '@contracts/api/dto/users/UserDto';

// Hooks
import { useUsersTable } from '@Modal/hooks/users/useUsersTable';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';

// GraphQL
import { useGetUsersQuery } from '@Src/generated.graphql';

export const UsersTable: React.FC = () => {
  const { providersListFilters, columnVisibility, visiblityCustomizable } = useUsersTable();
  const columns = useUserTableColumns();
  const { userPermissions } = useAuthorization();
  const permissionToViewUsers = userPermissions?.includes('CAN_VIEW_BACKOFFICE_USERS');
  const { data, loading } = useGetUsersQuery({
    skip: !permissionToViewUsers,
  });

  const visibleColumns = useMemo(
    () => applyColumnVisibility(columns, providersListFilters),
    [columns, columnVisibility]
  );

  const header = useMemo(
    () => (
      <TableHeadPresenter>
        <TableHeadColumnsPresenter columns={visibleColumns} />
        {/* @ts-ignore */}
        <ColumnVisibilityPresenter columns={columns} content={visiblityCustomizable} />
      </TableHeadPresenter>
    ),
    [columns]
  );

  if (!permissionToViewUsers) return <h1>No Permission</h1>;
  if (loading) return <LoadingScreen />;

  return (
    <>
      <TableContainerEx sx={{ height: '78vh' }} pageNumber={1}>
        <TableEx name="UsersList">
          {header}
          <TableBodyEx>
            {/* @ts-ignore */}
            {data?.users?.map((row: UserItemDto) => (
              <TableRowPresenter
                id={row.id}
                key={row.id}
                columns={visibleColumns}
                row={row}
                onClick={() => {}}
              />
            ))}
          </TableBodyEx>
        </TableEx>
      </TableContainerEx>
    </>
  );
};
