import { useMemo } from 'react';
// Components
import { Stack } from '@mui/material';
import { RHFSelect, RHFTextField } from '@Components/hook-form';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';

// Styles
import Styles from './CreateRoleContent.module.scss';
import { useGetProvidersQuery, useGetRolesQuery } from '@Src/generated.graphql';

const useDropDown = () => {
  const { data: providersData } = useGetProvidersQuery();
  const { data: rolesData } = useGetRolesQuery();
  const roleDropdown = useMemo(
    () =>
      rolesData?.roles?.map(({ name, id }) => (
        <MenuItemEx key={name} value={id} name="Users">
          {name}
        </MenuItemEx>
      )),
    [rolesData]
  );
  const providersDropdown = useMemo(
    () =>
      providersData?.providers?.map(({ name, id }) => (
        <MenuItemEx key={name} value={id} name="Users">
          {name}
        </MenuItemEx>
      )),
    [providersData]
  );
  return {
    roles: roleDropdown,
    providers: providersDropdown,
  };
};

export const CreateUserContent = () => {
  const dropdown = useDropDown();

  return (
    <Stack className={Styles.CreateRoleContent}>
      <RHFTextField name="email" label="Email" type="email" />
      <RHFTextField name="password" label="Password" />
      <RHFSelect label="Provider" name="providerId" variant="outlined">
        {dropdown.providers}
      </RHFSelect>
      <RHFSelect label="Role" name="roleIds" variant="outlined">
        {dropdown.roles}
      </RHFSelect>
    </Stack>
  );
};
