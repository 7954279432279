// System
import { useDispatch } from 'react-redux';
import { Stack, Typography, Button } from '@mui/material';

// Hooks
import useResponsive from '@Hooks/shared/useResponsive';
import { useCreateProvider } from '@Modal/hooks/providers/useCreateProvider';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const ProvidersTitle: React.FC = () => {
  const isDesktop = useResponsive('up', 'sm');
  const { userPermissions } = useAuthorization();
  const permissionToManageProviders = userPermissions?.includes('CAN_MANAGE_PROVIDERS');
  const dispatch = useDispatch();
  const { setShowCreateProviderForm } = useCreateProvider();
  const handleOpenCreateProviderModal = () => dispatch(setShowCreateProviderForm(true));

  return (
    <AtLocator type="container" name="customerListTitle">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingBottom: 2 }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">Providers</Typography>
        </Stack>
        {permissionToManageProviders && (
          <AtLocator type="button" name="CreateVehicle">
            <Button
              sx={{ width: !isDesktop ? 240 : null }}
              size="large"
              variant="contained"
              onClick={handleOpenCreateProviderModal}
            >
              Add provider
            </Button>
          </AtLocator>
        )}
      </Stack>
    </AtLocator>
  );
};
