// System
import React, { useMemo } from 'react';

// Components
import { TableBodyEx, TableContainerEx, TableEx } from '@Components/shared/tables/components';
import {
  ColumnVisibilityPresenter,
  TableHeadColumnsPresenter,
  TableHeadPresenter,
  TableRowPresenter,
} from '@Components/shared/tables/presenters';
import { LoadingScreen } from '@Components/shared/loaders/LoadingScreen';

// Utils
import { applyColumnVisibility } from '@utils/data/applyColumnVisibility';

//  Table
import { useProviderTableColumns } from './useProviderTableColumns';

// Redux
import { ProviderItemDto } from '@contracts/api/dto/providers/ProviderDto';
import { useProvidersTable } from '@Modal/hooks/providers/useProvidersTable';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';
import { useProviderDrawer } from '@Modal/hooks/providers/useProviderDrawer';

// Graphql
import { useGetProvidersQuery } from '@Src/generated.graphql';

export const ProviderTable: React.FC = () => {
  const { providersListFilters, columnVisibility, visiblityCustomizable } = useProvidersTable();
  const columns = useProviderTableColumns();
  const { handleProviderRowClick } = useProviderDrawer();
  const { userPermissions } = useAuthorization();

  const permissionToViewProviders = userPermissions?.includes('CAN_VIEW_PROVIDERS');
  const { data, loading } = useGetProvidersQuery({ skip: !permissionToViewProviders });

  const visibleColumns = useMemo(
    () => applyColumnVisibility(columns, providersListFilters),
    [columns, columnVisibility]
  );

  const header = useMemo(
    () => (
      <TableHeadPresenter>
        <TableHeadColumnsPresenter columns={visibleColumns} />
        {/* @ts-ignore */}
        <ColumnVisibilityPresenter columns={columns} content={visiblityCustomizable} />
      </TableHeadPresenter>
    ),
    [columns]
  );

  if (!permissionToViewProviders) return <h1>No Permission</h1>;
  if (loading) return <LoadingScreen />;

  return (
    <>
      <TableContainerEx sx={{ height: '78vh' }} pageNumber={1}>
        <TableEx name="ProvidersList">
          {header}
          <TableBodyEx>
            {/* @ts-ignore */}
            {data?.providers?.map((row: ProviderItemDto) => (
              <TableRowPresenter
                id={row.id}
                key={row.id}
                columns={visibleColumns}
                row={row}
                onClick={() => {
                  handleProviderRowClick(row?.id);
                }}
              />
            ))}
          </TableBodyEx>
        </TableEx>
      </TableContainerEx>
    </>
  );
};
