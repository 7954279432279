// System
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { GoogleMapsLoader } from '@Components/shared/maps/GoogleMapsLoader';

// Hooks
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useLiveMap } from '@Hooks/shared/useLiveMap';
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';
import { useVehicleMap } from '@Modal/hooks/vehicles/vehicles/useVehicleMap';
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Features
import { MapZoneViewerOperator } from '@Features/maps/MapZoneViewerMobile';

// Types
import { AppDispatch } from 'src/app/store';

// Dictionary slices
import { getMapZoneTypes, getVehicleServiceStates } from '@Modal/reducers/generalSlice';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';

export const OperatorPage = () => {
  // Redux
  const { activeBrandId } = useBranches();
  const { getAllMapZones } = useVehicleMap();
  // const { handleDecodeToken } = useAuthorization();
  const { handleGetAllOperatorVehicles } = useOperatorVehicles();
  const { mapZoneList, setActiveMapZoneType, activeMapZoneType } = useBranchesMaps();
  const { mapZoneTypes, vehicleServiceStates } = useGeneralStore();

  // Hooks
  const [mapBound, setBound] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    handleGetAllOperatorVehicles(mapBound);
  }, [mapBound]);

  useEffect(() => {
    if (!mapZoneTypes.length) {
      dispatch(getMapZoneTypes());
    }

    if (!vehicleServiceStates.length) {
      dispatch(getVehicleServiceStates());
    }
  }, []);

  // useEffect(() => {
  //   handleDecodeToken();
  // }, []);

  useEffect(() => {
    dispatch(setActiveMapZoneType('ALL'));
    getAllMapZones();
    dispatch(getVehicleServiceStates());
  }, [mapZoneTypes]);

  return (
    <>
      <Box sx={{ width: '100vw' }}>
        <AtLocator type="special-field" name="zones" box>
          <GoogleMapsLoader
            component={MapZoneViewerOperator}
            props={{
              mapZonesList: mapZoneList,
              mapZoneType: activeMapZoneType,
              setBound: (bound) => console.log(bound),
            }}
          />
        </AtLocator>
      </Box>
    </>
  );
};
