import { noop } from '@utils/noop';
import * as Yup from 'yup';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string, number } from 'yup';

export type SetupProviderFormState = {
  name: string | null;
  apiUrl: string | null;
  maxWin: number | null;
  minBet: number | null;
  maxBet: number | null;
  betSuggestions: string | string[];
  minAutoCashout: number | null;
  maxAutoCashout: number | null;
  defaultAutoCashout: number | null;
  language: string | null;
  currency: string | null;
};

export const setupProviderFormStateDefault: SetupProviderFormState = {
  name: '',
  apiUrl: '',
  maxWin: null,
  minBet: null,
  maxBet: null,
  betSuggestions: '',
  minAutoCashout: null,
  maxAutoCashout: null,
  defaultAutoCashout: null,
  language: '',
  currency: '',
};

// @ts-ignore
export const setupProviderSchemaDefault: ObjectSchema<Partial<SetupProviderFormState>> =
  object<SetupProviderFormState>().shape({
    name: string().required('name is required field'),
    apiUrl: string().required('api url is required field'),
    maxWin: number().required('maximum win is required field'),
    minBet: number().required('minimum bey is required field'),
    maxBet: number().required('maximum bet is required field'),
    betSuggestions: Yup.mixed()
      .required('Bet suggestions are required')
      .test(
        'is-valid-type',
        'Bet suggestions must be a string, an array of strings, a number, or an array of numbers',
        (value) =>
          typeof value === 'string' ||
          typeof value === 'number' ||
          (Array.isArray(value) &&
            value.every((item) => typeof item === 'string' || typeof item === 'number'))
      ),
    minAutoCashout: number().required('minimum auto cashout is required field'),
    maxAutoCashout: number().required('maximum auto cashout is required field'),
    defaultAutoCashout: number().required('default auto cashout is required field'),
    language: string().required('language is required field'),
    currency: string().required('currency is required field'),
    passPhrase: string().required('pass phrase key is required field'),
  });

type SetupProviderFormContextType = {
  schema: ObjectSchema<Partial<SetupProviderFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupProviderFormState>>>;
  methods?: UseFormReturn<SetupProviderFormState>;
};

const createProviderFormContextDefault = {
  schema: setupProviderSchemaDefault,
  setSchema: noop,
} satisfies SetupProviderFormContextType;

export const SetupProviderFormContext = createContext<SetupProviderFormContextType>(
  createProviderFormContextDefault
);

export const useSetupProviderForm = () => {
  const { methods } = useContext(SetupProviderFormContext);
  if (!methods) {
    throw new Error('SetupProviderFormContext is empty');
  }

  return methods;
};

export const useManageSetupProviderSchema = () => {
  const { setSchema } = useContext(SetupProviderFormContext);

  return { setSchema };
};
