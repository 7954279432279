// System
import { Box } from '@mui/system';
import { useRef } from 'react';
import { Button, TextField, Typography } from '@mui/material';

// Assets
import aviatorAuth from './aviatorAuth.png';

// Hooks
import useResponsive from '@Hooks/shared/useResponsive';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';

// Styles
import Styles from './Authorization.module.scss';

export const AuthorizationPage = () => {
  const { handleLogInReq } = useAuthorization();
  const formDataRef = useRef({
    email: '',
    password: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formDataRef.current[name as keyof typeof formDataRef.current] = value;
  };
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box className={Styles.Authorization}>
      {isMobile ? null : (
        <Box>
          <img
            src={`${aviatorAuth}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              borderRadius: '8px',
            }}
          />
        </Box>
      )}

      <Box className={Styles.FormContainer}>
        <Typography className={Styles.HeaderTitle}>Sign in to the account</Typography>
        <form
          className={Styles.SubFormContainer}
          onSubmit={(event) => {
            event.preventDefault();
            handleLogInReq(formDataRef?.current?.email, formDataRef?.current?.password);
          }}
        >
          <TextField
            className={Styles.FormInput}
            placeholder="Email address"
            label="Email address"
            name="email"
            onChange={handleChange}
          />
          <TextField
            className={Styles.FormInput}
            id="password"
            name="password"
            label="Password"
            type="password"
            onChange={handleChange}
          />
          <Button type="submit" className={Styles.FormButton} variant="contained">
            Log in
          </Button>
        </form>
      </Box>
    </Box>
  );
};
