import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

// Components
import ProtectedRoute from '@Features/account/ProtectedRoute';
import DashboardLayout from '@Components/layouts/dashboard/DashboardLayout';

// Pages
import { RolesPage } from './RolesPage';
import { UsersPage } from './UsersPage';
import { ProvidersPage } from './Providers';
import { CampaignsPage } from './Campaigns';
import { OperatorPage } from './OperatorPage';
import { AnalyticsPage } from './AnalyticPage';
import { RoundsPage } from './Rounds/RoundsPage';
import NotFoundRoute from '@Routes/NotFoundRoute';
import { HealthCheck } from '@Routes/healthcheck';
import { RouteConfig } from '@Hooks/shared/useRoles';
import { AuthorizationPage } from './AuthorizationPage';
import { TransactionPage } from './TransactionPage/TransactionPage';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';

export const AppRouter = () => {
  const { permissionedRoutes } = useAuthorization();

  const safeRoutes: string[] = permissionedRoutes?.length
    ? permissionedRoutes.map((obj: RouteConfig) => obj.path)
    : [];

  return (
    <Suspense fallback="">
      <Routes>
        <Route element={<DashboardLayout />}>
          <Route element={<ProtectedRoute allowedRoutes={safeRoutes} />}>
            <Route path="/providers" Component={ProvidersPage} />
            <Route path="/rounds" Component={RoundsPage} />
            <Route path="/transactions" Component={TransactionPage} />
            <Route path="/roles" Component={RolesPage} />
            <Route path="/users" Component={UsersPage} />
            <Route path="/campaigns" Component={CampaignsPage} />
            <Route path="/analytics" Component={AnalyticsPage} />
          </Route>
          <Route path="/notFound" Component={NotFoundRoute} />
        </Route>
        <Route path="/" Component={AuthorizationPage} />
        <Route path="/operator" Component={OperatorPage} />
        <Route path="/healthcheck" element={<HealthCheck />} />
      </Routes>
    </Suspense>
  );
};
