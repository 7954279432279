import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  accessToken: null,
  currentUser: null,
  userPermissions: null,
  permissionedRoutes: [],
};

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },
    setUserPermissions: (state, { payload }) => {
      state.userPermissions = payload;
    },
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    setPermissionedRoutes: (state, { payload }) => {
      state.permissionedRoutes = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: authorizationReducer } = authorizationSlice;
export const { setAccessToken, setUserPermissions, setCurrentUser, setPermissionedRoutes } =
  authorizationSlice.actions;
