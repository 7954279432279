// System
import { Stack, Typography } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const RoundsTitle: React.FC = () => {
  return (
    <AtLocator type="container" name="roundsListTitle">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingBottom: 2 }}
      >
        <Typography variant="h3">Rounds</Typography>
      </Stack>
    </AtLocator>
  );
};
