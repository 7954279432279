// System
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { parseISO } from 'date-fns';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

// Components
import { EnumTableColumnHeader, StringTableColumnHeader } from '@Components/shared/tables/columns';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { NumberRangeTableColumnHeader } from '@Components/shared/tables/columns/NumberRangeTableColumnHeader';
import { DateTableColumnHeader } from '@Components/shared/tables/columns/DateTableColumnHeader/DateTableColumnHeader';

// Contracts
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';
import { Filterable } from '@contracts/common/traits/Filterable';
import { ProviderTransactionItemDto } from '@contracts/api/dto/ProviderTransaction/ProviderTransactionDto';

// Utils
import {
  ProviderTransactionListColumns,
  ProviderTransactionListFilter,
  providerTransactionListColumns,
} from '@utils/tables/providerTransaction/providerTransactionListFilter';

// Features
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Enum
import {
  ProviderTransactionTableOperationEnum,
  ProviderTransactionTableReasonEnum,
} from '../ProviderTransactionTableEnums';

// Redux
import { useDispatch } from 'react-redux';
import { useProviderTransactionsTable } from '@Modal/hooks/providerTransactions/useProviderTransactionsTable';

type HeaderOptions = {
  filter: Filterable<ProviderTransactionListFilter>;
};

type Columns = TableColumnDescriptionArray<
  ProviderTransactionItemDto,
  HeaderOptions,
  typeof providerTransactionListColumns
>;

type Column = TableColumnDescription<
  ProviderTransactionItemDto,
  HeaderOptions,
  ProviderTransactionListColumns
>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const useProviderTransactionTableColumns = () => {
  const ellipsis = useEllipsis();
  const styles = useSx();
  const dispatch = useDispatch();
  const { handleFilterSelect, setProviderTransactionTableFilters } = useProviderTransactionsTable();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'providerTransaction._id',
          title: 'ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'id'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?._id && <TruncateText text={row?._id} maxLength={8} textStyles={styles.id} />}
            </Box>
          ),
        },
        {
          name: 'providerTransaction.createdAt',
          title: 'Crated At',
          sx: { minWidth: '170px', maxWidth: '240px' },
          header: ({ title }) => (
            <DateTableColumnHeader
              title={title}
              onApply={(startDate, endDate) => {
                startDate?.length
                  ? dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'createdStartDate',
                        value: startDate,
                      })
                    )
                  : null;
                endDate?.length
                  ? dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'createdEndDate',
                        value: endDate,
                      })
                    )
                  : null;
              }}
              onReset={() => {
                dispatch(
                  setProviderTransactionTableFilters({
                    codeName: 'createdStartDate',
                    value: undefined,
                  })
                );
                dispatch(
                  setProviderTransactionTableFilters({
                    codeName: 'createdEndDate',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.createdAt && (
                <DateLabel date={new Date(parseISO(row?.createdAt))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'providerTransaction.category',
          title: 'Category',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName={'category'}
              options={[
                { key: 'Cashout', value: 'CASH_OUT' },
                { key: 'Cashin', value: 'CASH_IN' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.category}</Box>,
        },
        {
          name: 'providerTransaction.language',
          title: 'Language',
          sx: { minWidth: '150px', maxWidth: '220px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName="language"
              options={[
                { key: 'Georgian', value: 'ka' },
                { key: 'English', value: 'en' },
                { key: 'Russian', value: 'ru' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.language}</Box>,
        },
        {
          name: 'providerTransaction.currency',
          title: 'Currency',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName="currency"
              options={[
                { key: 'GEL', value: 'GEL' },
                { key: 'USD', value: 'USD' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.currency}</Box>,
        },
        {
          name: 'providerTransaction.roundId',
          title: 'Round Id',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'roundId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.roundId && (
                <TruncateText text={row?.roundId} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'providerTransaction.transactionId',
          title: 'Transaction Id',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'transactionId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.transactionId && (
                <TruncateText text={row?.transactionId} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'providerTransaction.operation',
          title: 'Operation',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName={'operation'}
              options={[
                { key: 'Clear Bet', value: 'CLEAR_BET' },
                { key: 'Place Auto Bet', value: 'PLACE_BET_AUTO_BET' },
                { key: 'Place Manual Bet', value: 'PLACE_MANUAL_BET' },
                { key: 'Manual Cashout', value: 'MANUAL_CASHOUT' },
                { key: 'Auto Cashout', value: 'AUTO_CASHOUT' },
                { key: 'Refund Credit', value: 'REFUND_CREDIT' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {
                ProviderTransactionTableOperationEnum[
                  row?.operation as keyof typeof ProviderTransactionTableOperationEnum
                ]
              }
            </Box>
          ),
        },
        {
          name: 'providerTransaction.reason',
          title: 'Reason',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName={'reason'}
              options={[
                { key: 'Freebet win', value: 'FREEBET_WIN' },
                { key: 'Normal win', value: 'NORMAL_WIN' },
                { key: 'Reverse fund', value: 'REVERSE_FUND' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {
                ProviderTransactionTableReasonEnum[
                  row?.reason as keyof typeof ProviderTransactionTableReasonEnum
                ]
              }
            </Box>
          ),
        },
        {
          name: 'providerTransaction.providerId',
          title: 'Provider Id',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'providerId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.providerId && (
                <TruncateText text={row?.providerId} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'providerTransaction.playerId',
          title: 'Player Id',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'playerId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.playerId && (
                <TruncateText text={row?.playerId} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'providerTransaction.amount',
          title: 'Amount',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'minAmount',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'minAmount',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'maxAmount',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'maxAmount',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setProviderTransactionTableFilters({
                    codeName: 'minAmount',
                    value: undefined,
                  })
                );
                dispatch(
                  setProviderTransactionTableFilters({
                    codeName: 'maxAmount',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.amount}</Box>,
        },
        {
          name: 'providerTransaction.multiplier',
          title: 'Multiplier',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'minMultiplier',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'minMultiplier',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'maxMultiplier',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'maxMultiplier',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setProviderTransactionTableFilters({
                    codeName: 'minMultiplier',
                    value: undefined,
                  })
                );
                dispatch(
                  setProviderTransactionTableFilters({
                    codeName: 'maxMultiplier',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.multiplier}</Box>,
        },
        {
          name: 'providerTransaction.balance',
          title: 'Balance',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'minBalance',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'minBalance',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'maxBalance',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setProviderTransactionTableFilters({
                        codeName: 'maxBalance',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setProviderTransactionTableFilters({
                    codeName: 'minBalance',
                    value: undefined,
                  })
                );
                dispatch(
                  setProviderTransactionTableFilters({
                    codeName: 'maxBalance',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {Math.round((row?.balance + Number.EPSILON) * 100) / 100}
            </Box>
          ),
        },
        {
          name: 'providerTransaction.gameId',
          title: 'Game Id',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'gameId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.gameId}</Box>,
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
