// System
import React, { useEffect, useMemo } from 'react';

// Components
import { TablePagination } from '@mui/material';
import { TableBodyEx, TableContainerEx, TableEx } from '@Components/shared/tables/components';
import {
  ColumnVisibilityPresenter,
  TableHeadColumnsPresenter,
  TableHeadPresenter,
  TableRowPresenter,
} from '@Components/shared/tables/presenters';
import { LoadingScreen } from '@Components/shared/loaders/LoadingScreen';

// Utils
import { applyColumnVisibility } from '@utils/data/applyColumnVisibility';

//  Table
import { useTransactionTableColumns } from './useTransactionTableColumns';

// Redux
import { useDispatch } from 'react-redux';
import { useTransactionTable } from '@Modal/hooks/transaction/useTransactionTable';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';
import { useAvTransactionDrawer } from '@Modal/hooks/transaction/useAvTransactionDrawer';
// Graphql
import { usePaginatedAviatorRoundTransactionsQuery } from '@Src/generated.graphql';

// Type
import { TransactionItemDto } from '@contracts/api/dto/transaction/TransactionDto';

export const TransactionTable: React.FC = () => {
  const dispatch = useDispatch();
  const {
    page,
    limit,
    total,
    setPage,
    setLimit,
    setTotal,
    columnVisibility,
    visiblityCustomizable,
    transactionListFilters,
    roundTransactionsTableFilters,
  } = useTransactionTable();
  const { userPermissions } = useAuthorization();
  const columns = useTransactionTableColumns();
  const { handleAvTransactionRowClick } = useAvTransactionDrawer();

  const permissionToViewRoundTransactions = userPermissions?.includes(
    'CAN_VIEW_AVIATOR_ROUND_TRANSACTIONS'
  );

  const { data, loading, refetch } = usePaginatedAviatorRoundTransactionsQuery({
    variables: {
      limit,
      page,
      filter: roundTransactionsTableFilters,
    },
    skip: !permissionToViewRoundTransactions,
  });

  const visibleColumns = useMemo(
    () => applyColumnVisibility(columns, transactionListFilters),
    [columns, columnVisibility]
  );

  useEffect(() => {
    if (data?.paginatedAviatorRoundTransactions) {
      const { total, limit, page } = data.paginatedAviatorRoundTransactions;
      dispatch(setTotal(total));
      dispatch(setLimit(limit));
      dispatch(setPage(page));
    }
  }, [data]);

  const header = useMemo(
    () => (
      <TableHeadPresenter>
        <TableHeadColumnsPresenter columns={visibleColumns} />
        {/* @ts-ignore */}
        <ColumnVisibilityPresenter columns={columns} content={visiblityCustomizable} />
      </TableHeadPresenter>
    ),
    [columns]
  );

  if (!permissionToViewRoundTransactions) return <h1>No Permission</h1>;
  if (loading) return <LoadingScreen />;
  const handlePageChange = (event: unknown, newPage: number) => {
    dispatch(setPage(newPage + 1));
    refetch();
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
    dispatch(setPage(1));
    refetch();
  };

  return (
    <>
      <TableContainerEx sx={{ height: '70vh' }} pageNumber={1}>
        <TableEx name="RoundsTransactionList">
          {header}
          <TableBodyEx>
            {/* @ts-ignore */}
            {data?.paginatedAviatorRoundTransactions?.docs?.map((row: TransactionItemDto) => (
              <TableRowPresenter
                id={row._id}
                key={row._id}
                columns={visibleColumns}
                row={row}
                onClick={() => handleAvTransactionRowClick(row)}
              />
            ))}
          </TableBodyEx>
        </TableEx>
      </TableContainerEx>
      <TableEx name="CustomerListFooter">
        <TablePagination
          component="div"
          count={total}
          page={page - 1}
          rowsPerPage={limit}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableEx>
    </>
  );
};
