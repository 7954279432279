export enum ProviderTransactionTableOperationEnum {
  'CLEAR_BET' = 'Clear Bet',
  'PLACE_AUTO_BET' = 'Place Auto Bet',
  'PLACE_MANUAL_BET' = 'Place Manual Bet',
  'MANUAL_CASHOUT' = 'Manual Cashout',
  'AUTO_CASHOUT' = 'Auto Cashout',
  'REFUND_CREDIT' = 'Refund Credit',
  'PLACE_BET_AUTO_BET' = 'Place Auto Bet',
}

export enum ProviderTransactionTableReasonEnum {
  'FREEBET_WIN' = 'Freebet Win',
  'NORMAL_WIN' = 'Normal Win',
  'REVERSE_FUND' = 'Reverse Fund',
  'Bet placed successfully' = 'Bet Placed Successfully',
}
