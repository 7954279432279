// System
import React, { useMemo } from 'react';

// Components
import { TableBodyEx, TableContainerEx, TableEx } from '@Components/shared/tables/components';
import {
  ColumnVisibilityPresenter,
  TableHeadColumnsPresenter,
  TableHeadPresenter,
  TableRowPresenter,
} from '@Components/shared/tables/presenters';
import { LoadingScreen } from '@Components/shared/loaders/LoadingScreen';

// Utils
import { applyColumnVisibility } from '@utils/data/applyColumnVisibility';

//  Table
import { useAviatorRoleTableColumns } from './useAviatorRoleTableColumns';

// Redux
import { AviatorRoleItemDto } from '@contracts/api/dto/aviatorRoles/RoleDto';

// Hooks
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';
import { useAviatorRolesTable } from '@Modal/hooks/aviatorRoles/useAviatorRolesTable';
import { useAviatorRoleDrawer } from '@Modal/hooks/aviatorRoles/useAviatorRoleDrawer';

// GraphQL
import { useGetRolesQuery } from '@Src/generated.graphql';

export const AviatorRolesTable: React.FC = () => {
  const { aviatorRolesListFilters, columnVisibility, visiblityCustomizable } =
    useAviatorRolesTable();
  const columns = useAviatorRoleTableColumns();
  const { handleAviatorRoleRowClick } = useAviatorRoleDrawer();
  const { userPermissions } = useAuthorization();
  const permissionToViewRoles = userPermissions?.includes('CAN_VIEW_BACKOFFICE_ROLES');
  const { data, loading } = useGetRolesQuery({
    skip: !permissionToViewRoles,
  });
  const visibleColumns = useMemo(
    () => applyColumnVisibility(columns, aviatorRolesListFilters),
    [columns, columnVisibility]
  );

  const header = useMemo(
    () => (
      <TableHeadPresenter>
        <TableHeadColumnsPresenter columns={visibleColumns} />
        {/* @ts-ignore */}
        <ColumnVisibilityPresenter columns={columns} content={visiblityCustomizable} />
      </TableHeadPresenter>
    ),
    [columns]
  );
  if (!permissionToViewRoles) return <h1>No Permission</h1>;
  if (loading) return <LoadingScreen />;

  return (
    <>
      <TableContainerEx sx={{ height: '78vh' }} pageNumber={1}>
        <TableEx name="AviatorRolesList">
          {header}
          <TableBodyEx>
            {/* @ts-ignore */}
            {data?.roles?.map((row: AviatorRoleItemDto) => (
              <TableRowPresenter
                id={row.id}
                key={row.id}
                columns={visibleColumns}
                row={row}
                onClick={() => handleAviatorRoleRowClick(row.id)}
              />
            ))}
          </TableBodyEx>
        </TableEx>
      </TableContainerEx>
    </>
  );
};
